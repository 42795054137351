// import {Link} from 'react-router-dom';
// import{useState} from 'react';
// import axios from "axios";
import moment from "moment";
import { useAuth } from "../../context/auth";
import clsx from "clsx";

export default function Provider_MessagerCardSingleThread({ currentThread }) {
  // context
  const [auth, setAuth] = useAuth();

  const myId = auth.user._id;

  // console.log(currentThread);

  const date = moment(currentThread?.createdAt).format("hh:mm");

  const isSelf = currentThread?.postedBy === myId;

  return (
    <div
      className={clsx("flex", {
        "justify-end": isSelf,
      })}
    >
      <div
        className={clsx(
          "flex items-end py-3 px-4 gap-5 rounded-3xl max-w-[80%]",
          {
            "bg-blue text-white": isSelf,
            "bg-[#f2f2f2] text-black": !isSelf,
          }
        )}
      >
        <p className="m-0">{currentThread?.message}</p>

        <p
          className={clsx("m-0", {
            "text-white/70": isSelf,
            "  text-[#3C6E7180]": !isSelf,
          })}
        >
          {date}
        </p>
      </div>
    </div>
  );
}
