import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import SearchFormHomepage from "../components/forms/SearchFormHomepage";
import HomeSteps from "../components/connector/HomeSteps";
import AdCardHome from "../components/cards/AdCardHome";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "../helpers/utilities/LocalStorage.js";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Modal } from "antd";
import CareAssessmentFormShort from "../components/forms/CareAssessmentForm_Short.jsx";
import HomeAccordion from "../components/misc/homeAccordion.jsx";
import Slider from "../components/misc/slider.jsx";
import Logo from "../components/misc/Logo.jsx";
import logo from "../assets/images/sch-logo.png";

function Home() {
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const [listings, setListings] = useState();
  const [openModel, setModel] = useState(0);
  const [careAssessmentComplete, setCareAssessmentComplete] = useState(false);

  const referrerUrl = getFromLocalStorage("sch-referrer");

  // fetches recommended listings upon page load
  useEffect(() => {
    setCareAssessmentComplete(getFromLocalStorage("care-assessment-complete"));
    fetchAds();
  }, []);

  // fetches recommended listings upon assessment completion
  useEffect(() => {
    fetchAds();
  }, [careAssessmentComplete]);

  const fetchAds = async () => {
    try {
      const { data } = await axios.get(`/ads`);
      setListings(data.listings);
    } catch (error) {
      console.log(error);
    }
  };

  const carouselSettings = useMemo(() => {
    return {
      dots: false,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: careAssessmentComplete ? 3 : 2,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            arrows: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };
  }, [careAssessmentComplete]);

  const navigateToRecommendations = () => {
    navigate(
      getFromLocalStorage("care-assessment")?.context?.recommendationUrl
    );
  };
  return (
    <>
      <Helmet>
        <title>SeniorCareHomes.Org</title>
        <meta
          name="description"
          content="Find and directly connect with state-licensed senior care homes near you"
        />
      </Helmet>
      <div className="bg-hero-home bg-center bg-no-repeat bg-cover">
        <div className="bg-black/50 flex items-center justify-center">
          <div className="max-w-6xl py-16 md:py-40 lg:pt-28 lg:pb-24 ">
            <h1 className="text-2xl md:text-4xl lg:text-5xl text-white text-center sm:px-4 md:px-7 lg:px-10">
              Finding Senior Living, Regardless of Financial Background
            </h1>
            <div className="max-w-2xl mx-auto mt-8 sm:mt-10 md:mt-12 lg:mt-14 max-md:hidden">
              <SearchFormHomepage />
            </div>
            <div className="flex justify-center">
              <button className="py-2.5 !px-8 text-sm font-medium bg-green text-white border-none rounded-full md:hidden">
                Search Providers
              </button>
            </div>
            {/* <div className="flex items-center justify-center mt-8 sm:mt-10 md:mt-12 lg:mt-14 lgTablet:hidden ">
              <Link
                to="/"
                className="btn-sm bg-green block w-fit !rounded-full text-white border-none  "
              >
                Search Providers
              </Link>{" "}
            </div> */}
          </div>
        </div>
      </div>
      <div className="max-container grid grid-cols-1 lgTablet:grid-cols-10 !pb-0 !pt-4 lgTablet:!pb-8 lgTablet:!pt-8">
        {/* Show and hide this section depending on whether care assessment is complete or not */}
        {careAssessmentComplete && (
          <div className="lgTablet:col-span-12 flex flex-col w-full gap-2.5 items-center justify-center mb-2">
            <div className="flex flex-col w-full items-center justify-center">
              <h1 className="mt-5 text-4xl  font-medium text-center text-primaryBlack">
                Homes near you
              </h1>
            </div>
          </div>
        )}
        {!careAssessmentComplete && (
          <>
            <div className="lgTablet:col-span-4 flex flex-col w-full gap-2.5 items-center justify-center mb-2">
              <div className="flex flex-col w-full items-center justify-center">
                <h1 className="mt-5 text-4xl  font-medium text-center text-primaryBlack">
                  Homes near you
                </h1>
              </div>
              <p className="text-center  lgTablet:text-2xl max-w-md">
                Answer a few questions for compatible home recommendations!
              </p>
              <button
                className="btn-sm bg-green text-white border-white "
                onClick={() => {
                  setModel(1);
                }}
              >
                My Recommendations
              </button>
            </div>
          </>
        )}

        <div
          className={`${
            careAssessmentComplete
              ? "lgTablet:col-span-10 mb-5"
              : "lgTablet:col-span-6 mb-5"
          } max-lg:max-w-[842px] mx-auto w-full`}
        >
          {/*           <h1 className="mt-5 text-4xl  font-medium text-center text-primaryBlack  lgTablet:hidden">
            Homes near you
          </h1> */}
          <Slider settings={carouselSettings} className="w-full slider py-3">
            {listings?.map((ad) => (
              <AdCardHome ad={ad} key={ad._id} />
            ))}
          </Slider>
        </div>
      </div>

      <div className="max-container rounded-2xl.5 lg:bg-bgPink !py-0 lg:!py-10 lgTablet:!px-32 flex flex-col items-center gap-10">
        <div className="bg-bgPink lg:bg-bgCard w-fit shadow-card rounded-2xl.5 p-10">
          <h1 className="text-lg md:text-3xl lg:text-4xl  font-medium text-center max-w-md text-primaryBlack">
            Finding Senior Care in a Few Simple Steps
          </h1>
        </div>

        <HomeSteps />
        {careAssessmentComplete ? (
          <button
            className="btn-sm bg-green text-white border-none"
            onClick={() => {
              navigateToRecommendations();
            }}
          >
            My Recommendations
          </button>
        ) : (
          <button
            className="btn-sm bg-green text-white border-none"
            onClick={() => {
              navigate("/client-care-assessment");
            }}
          >
            Get Started
          </button>
        )}
      </div>

      <div className="max-container mt-28">
        <div className="bg-providor-care bg-cover bg-center rounded-[20px] overflow-hidden  box-content">
          <div className="space-y-11 px-10 !pt-5 !pb-5 lg:px-16 md:!pt-36 md:!pb-24  bg-gradient-provider flex flex-col items-center lg:items-start">
            <div>
              {" "}
              <h2 className=" text-2xl lg:text-4xl text-center font-bold  text-white">
                Are you a Care Provider?
              </h2>
            </div>
            <Link
              to="/register/provider"
              className="btn-sm bg-green block w-fit text-white border-none"
            >
              Claim your Listing
            </Link>
          </div>
        </div>
      </div>

      <div className="max-container py-20">
        <div className="urls mx-auto w-[100%] max-w-7xl flex flex-col md:flex-row items-center md:items-start justify-center md:justify-between gap-5">
          <HomeAccordion data={seniorCareHomes} title={"Senior Care Homes"} />
          <HomeAccordion data={adultFamilyHomes} title={"Adult Family Homes"} />
          <HomeAccordion
            data={residentialCareHomes}
            title={"Residential Care Homes"}
          />
        </div>
      </div>

      {/* Take Assessment */}
      <Modal
        open={openModel === 1}
        onCancel={() => {
          setModel(0);
        }}
        title={
          <div className="modal-header-content d-flex justify-content-center">
            <Link to="/">
              <img src={logo} alt="site-logo" />
            </Link>
          </div>
        }
        footer={null}
      >
        <div className="mt-3">
          <CareAssessmentFormShort
            setCareAssessmentComplete={setCareAssessmentComplete}
            setModel={setModel}
          />
        </div>
      </Modal>
    </>
  );
}

export default Home;

// Arrays to map data for homes

const seniorCareHomes = [
  {
    href: "/listings/adult-family-home/washington/clark-county/vancouver",
    text: "Senior Care Homes in Vancouver, WA",
  },
  {
    href: "/listings/adult-family-home/washington/pierce-county/tacoma",
    text: "Senior Care Homes in Tacoma, WA",
  },
  {
    href: "/listings/adult-family-home/washington/snohomish-county/lynnwood",
    text: "Senior Care Homes in Lynnwood, WA",
  },
  {
    href: "/listings/adult-family-home/washington/snohomish-county/everett",
    text: "Senior Care Homes in Everett, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/federal-way",
    text: "Senior Care Homes in Federal Way, WA",
  },
  {
    href: "/listings/adult-family-home/washington/spokane-county/spokane",
    text: "Senior Care Homes in Spokane, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/kent",
    text: "Senior Care Homes in Kent, WA",
  },
  {
    href: "/listings/adult-family-home/washington/pierce-county/lakewood",
    text: "Senior Care Homes in Lakewood, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/renton",
    text: "Senior Care Homes in Renton, WA",
  },
  {
    href: "/listings/adult-family-home/washington/spokane-county/spokane-valley",
    text: "Senior Care Homes in Spokane Valley, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/auburn",
    text: "Senior Care Homes in Auburn, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/seattle",
    text: "Senior Care Homes in Seattle, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/bellevue",
    text: "Senior Care Homes in Bellevue, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/shoreline",
    text: "Senior Care Homes in Shoreline, WA",
  },
  {
    href: "/listings/adult-family-home/washington/pierce-county/puyallup",
    text: "Senior Care Homes in Puyallup, WA",
  },
  {
    href: "/listings/adult-family-home/washington/snohomish-county/marysville",
    text: "Senior Care Homes in Marysville, WA",
  },
  {
    href: "/listings/adult-family-home/washington/snohomish-county/edmonds",
    text: "Senior Care Homes in Edmonds, WA",
  },
  {
    href: "/listings/adult-family-home/washington/thurston-county/olympia",
    text: "Senior Care Homes in Olympia, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/bothell",
    text: "Senior Care Homes in Bothell, WA",
  },
  {
    href: "/listings/adult-family-home/washington/thurston-county/lacey",
    text: "Senior Care Homes in Lacey, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/des-moines",
    text: "Senior Care Homes in Des Moines, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-countyy/kirkland",
    text: "Senior Care Homes in Kirkland, WA",
  },
];

const adultFamilyHomes = [
  {
    href: "/listings/adult-family-home/washington/clark-county/vancouver",
    text: "Adult Family Homes in Vancouver, WA",
  },
  {
    href: "/listings/adult-family-home/washington/pierce-county/tacoma",
    text: "Adult Family Homes in Tacoma, WA",
  },
  {
    href: "/listings/adult-family-home/washington/snohomish-county/lynnwood",
    text: "Adult Family Homes in Lynnwood, WA",
  },
  {
    href: "/listings/adult-family-home/washington/snohomish-county/everett",
    text: "Adult Family Homes in Everett, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/federal-way",
    text: "Adult Family Homes in Federal Way, WA",
  },
  {
    href: "/listings/adult-family-home/washington/spokane-county/spokane",
    text: "Adult Family Homes in Spokane, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/kent",
    text: "Adult Family Homes in Kent, WA",
  },
  {
    href: "/listings/adult-family-home/washington/pierce-county/lakewood",
    text: "Adult Family Homes in Lakewood, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/renton",
    text: "Adult Family Homes in Renton, WA",
  },
  {
    href: "/listings/adult-family-home/washington/spokane-county/spokane-valley",
    text: "Adult Family Homes in Spokane Valley, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/auburn",
    text: "Adult Family Homes in Auburn, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/seattle",
    text: "Adult Family Homes in Seattle, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/bellevue",
    text: "Adult Family Homes in Bellevue, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/shoreline",
    text: "Adult Family Homes in Shoreline, WA",
  },
  {
    href: "/listings/adult-family-home/washington/pierce-county/puyallup",
    text: "Adult Family Homes in Puyallup, WA",
  },
  {
    href: "/listings/adult-family-home/washington/snohomish-county/marysville",
    text: "Adult Family Homes in Marysville, WA",
  },
  {
    href: "/listings/adult-family-home/washington/snohomish-county/edmonds",
    text: "Adult Family Homes in Edmonds, WA",
  },
  {
    href: "/listings/adult-family-home/washington/thurston-county/olympia",
    text: "Adult Family Homes in Olympia, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/bothell",
    text: "Adult Family Homes in Bothell, WA",
  },
  {
    href: "/listings/adult-family-home/washington/thurston-county/lacey",
    text: "Adult Family Homes in Lacey, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/des-moines",
    text: "Adult Family Homes in Des Moines, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-countyy/kirkland",
    text: "Adult Family Homes in Kirkland, WA",
  },
];

const residentialCareHomes = [
  {
    href: "/listings/adult-family-home/washington/clark-county/vancouver",
    text: "Residential Care Homes in Vancouver, WA",
  },
  {
    href: "/listings/adult-family-home/washington/pierce-county/tacoma",
    text: "Residential Care Homes in Tacoma, WA",
  },
  {
    href: "/listings/adult-family-home/washington/snohomish-county/lynnwood",
    text: "Residential Care Homes in Lynnwood, WA",
  },
  {
    href: "/listings/adult-family-home/washington/snohomish-county/everett",
    text: "Residential Care Homes in Everett, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/federal-way",
    text: "Residential Care Homes in Federal Way, WA",
  },
  {
    href: "/listings/adult-family-home/washington/spokane-county/spokane",
    text: "Residential Care Homes in Spokane, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/kent",
    text: "Residential Care Homes in Kent, WA",
  },
  {
    href: "/listings/adult-family-home/washington/pierce-county/lakewood",
    text: "Residential Care Homes in Lakewood, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/renton",
    text: "Residential Care Homes in Renton, WA",
  },
  {
    href: "/listings/adult-family-home/washington/spokane-county/spokane-valley",
    text: "Residential Care Homes in Spokane Valley, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/auburn",
    text: "Residential Care Homes in Auburn, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/seattle",
    text: "Residential Care Homes in Seattle, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/bellevue",
    text: "Residential Care Homes in Bellevue, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/shoreline",
    text: "Residential Care Homes in Shoreline, WA",
  },
  {
    href: "/listings/adult-family-home/washington/pierce-county/puyallup",
    text: "Residential Care Homes in Puyallup, WA",
  },
  {
    href: "/listings/adult-family-home/washington/snohomish-county/marysville",
    text: "Residential Care Homes in Marysville, WA",
  },
  {
    href: "/listings/adult-family-home/washington/snohomish-county/edmonds",
    text: "Residential Care Homes in Edmonds, WA",
  },
  {
    href: "/listings/adult-family-home/washington/thurston-county/olympia",
    text: "Residential Care Homes in Olympia, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/bothell",
    text: "Residential Care Homes in Bothell, WA",
  },
  {
    href: "/listings/adult-family-home/washington/thurston-county/lacey",
    text: "Residential Care Homes in Lacey, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-county/des-moines",
    text: "Residential Care Homes in Des Moines, WA",
  },
  {
    href: "/listings/adult-family-home/washington/king-countyy/kirkland",
    text: "Residential Care Homes in Kirkland, WA",
  },
];
