import { useState } from "react";
import { ReactComponent as SubtractIcon } from "../../assets/icons/subtractIcon.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/plusIcon.svg";
import clsx from "clsx";

export const SupportAccordion = () => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="space-y-3 border-b border-neutral-400 pb-9">
      <div className="flex items-center justify-between">
        <p
          className={clsx(
            "m-0 transition-all duration-300 font-roboto-flex text-2xl ",
            {
              "text-blue": expanded,
              "text-neutral-800": !expanded,
            }
          )}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit?
        </p>
        <button
          className="text-3xl h-5 w-5 flex items-center justify-center"
          onClick={() => setExpanded((prev) => !prev)}
        >
          {expanded && <SubtractIcon />}
          {!expanded && <AddIcon />}
        </button>
      </div>
      <div
        className={clsx("transition-all duration-300 overflow-hidden", {
          "max-h-screen ": expanded,
          "max-h-0 ": !expanded,
        })}
      >
        <p className="m-0 text-lg text-gray-500 font-roboto-flex max-w-4xl">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam.
        </p>
      </div>
    </div>
  );
};
