import React from "react";
import StepsCard from "../cards/StepsCard";
import boxImage1 from "../../assets/images/explore.svg";
import boxImage2 from "../../assets/images/connect.svg";
import boxImage3 from "../../assets/images/visit.svg";
import boxImage4 from "../../assets/images/choose.svg";

function HomeSteps() {
  return (
    <div className="grid lg:grid-cols-2 lg:gap-10">
      <StepsCard
        image={boxImage1}
        heading={"Complete Care Assessment"}
        content={
          "Take a quick & free care assessment to help us recommend qualified senior care homes that align with your unique needs."
        }
        index="1"
      />
      <StepsCard
        image={boxImage2}
        heading={"Explore Homes"}
        content={
          "Browse through a curated list of senior care homes in your neighborhood. Further filter results based on location, services, and cost of care."
        }
        index="2"
      />
      <StepsCard
        image={boxImage3}
        heading={"Connect with Care Providers "}
        content={
          "Initiate direct conversations with senior care providers. Ask questions, discuss needs, and gain a deeper understanding of what each home offers."
        }
        index="3"
      />
      <StepsCard
        image={boxImage4}
        heading={"Visit Homes"}
        content={
          "Schedule visits to the homes that resonate with you and your loved ones. Get a firsthand feel for the environment and community."
        }
        index="4"
      />
    </div>
  );
}

export default HomeSteps;
