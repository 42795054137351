import Resizer from "react-image-file-resizer";
import axios from "axios";
import { Avatar } from "antd";
import { IoCloseCircle } from "react-icons/io5";
import { RxUpload } from "react-icons/rx";

export default function ImageUpload({ ad, setAd }) {
  const handleUpload = async (e) => {
    try {
      let files = e.target.files;
      files = [...files];
      if (files?.length) {
        // console.log(files);
        setAd({ ...ad, uploading: true });

        files.map((file) => {
          new Promise(() => {
            Resizer.imageFileResizer(
              file,
              1080,
              720,
              "JPEG",
              100,
              0,
              async (uri) => {
                try {
                  const { data } = await axios.post("/upload-image", {
                    image: uri,
                  });
                  setAd((prev) => ({
                    ...prev,
                    photos: [data, ...prev.photos],
                    uploading: false,
                  }));
                } catch (error) {
                  console.log(error);
                  setAd({ ...ad, uploading: false });
                }
              },
              "base64"
            );
          });
        });
      }
    } catch (error) {
      console.log(error);
      setAd({ ...ad, uploading: false });
    }
  };

  const handleDelete = async (file) => {
    const answer = window.confirm("Delete Image?");
    if (!answer) return;
    setAd({ ...ad, uploading: true });
    try {
      const { data } = await axios.post("/remove-image", file);
      if (data?.ok) {
        setAd((prev) => ({
          ...prev,
          photos: prev.photos.filter((p) => p.Key !== file.Key),
          uploading: false,
        }));
      }
    } catch (error) {
      console.log(error);
      setAd({ ...ad, uploading: false });
    }
  };

  return (
    <div className="grid grid-cols-1 gap-2.5">
      {ad.photos.length > 0 && (
        <div className="grid grid-cols-1 gap-2.5 h-[346px] overflow-auto">
          <div className="relative">
            <img
              src={ad.photos[0]?.Location}
              alt="uploaded"
              className="w-full h-60 rounded-xl object-contain bg-lightestGray"
            />
            <button
              className="absolute top-1 right-1 text-red-500"
              onClick={() => handleDelete(ad.photos[ad.photos?.length - 1])}
            >
              <IoCloseCircle className="h-4 w-4" />
            </button>
          </div>
          <div className="relative grid grid-cols-3 gap-3">
            {ad.photos.slice(1, ad.photos.length).map((file, index) => (
              <div key={index} className="position-relative">
                <img
                  src={file?.Location}
                  alt="uploaded"
                  className="w-full h-24 rounded-xl object-contain bg-lightestGray"
                />
                <button
                  className="absolute top-1 right-1 text-red-500"
                  onClick={() => handleDelete(file)}
                >
                  <IoCloseCircle className="h-4 w-4" />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="w-full">
        <label
          className="cursor-pointer w-full flex items-center justify-center bg-lightestGray py-10 rounded-xl"
          style={{ textTransform: "capitalize" }}
        >
          <div className="flex flex-col items-center justify-center ">
            <RxUpload className="h-10 w-10" />
            <p className="m-0">Upload image (max 20)</p>
          </div>

          <input
            onChange={handleUpload}
            type="file"
            accept="image/*"
            multiple
            hidden
          />
        </label>
      </div>
    </div>
  );
}
