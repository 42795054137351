import { RiEditLine, RiUserLine } from "react-icons/ri";
import { Link } from "react-router-dom";

export const UserSettings = () => {
  return (
    <div className="!px-5 py-7 space-y-20">
      <div className="flex items-center justify-center md:justify-start">
        <p className="text-borderBlack text-xl md:text-3xl font-bold">
          Account Settings &gt; Profile
        </p>
      </div>
      <div className="space-y-12 max-w-2xl  px-7">
        <div className="flex items-center justify-center md:justify-start flex-col md:flex-row gap-8">
          <div className="flex flex-col items-center justify-center">
            <div className="relative h-32 w-32 bg-gray-200 rounded-full flex items-center justify-center">
              <RiUserLine className="h-10 w-10" />
              <button className="absolute bottom-1 right-0 flex items-center justify-center gap-2.5 bg-white rounded-[30px] shadow-listing-add-new p-2">
                <RiEditLine />
              </button>
            </div>
            <p className="m-0 text-2xl font-bold whitespace-nowrap">
              Puerto Rico
            </p>
          </div>
          <p className="m-0 text-base italic font-roboto-flex ">
            Providers with a profile picture get on average 3x more contact
            requests!
          </p>
        </div>
        <Link
          to="/"
          className="flex items-center justify-center border border-stroke20 rounded-full  text-black !p-5"
        >
          Change Password
        </Link>
      </div>
    </div>
  );
};
