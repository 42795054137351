import React, { useState, useEffect } from "react";
import list from "../../assets/images/list.svg";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import Logo from "../misc/Logo";
import { useAuth } from "../../context/auth";
import { Avatar } from "antd";
import { useMediaQuery } from "react-responsive";
import HeaderSearch from "../forms/headerSearchForm";

export default function Header() {
  // context
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();

  const [openNav, setOpenNav] = useState(false);
  const location = useLocation();
  const [providerDashboard, setProviderDashboard] = useState(false);
  const [clientDashboard, setClientDashboard] = useState(false);
  const [adminDashboard, setAdminDashboard] = useState(false);
  const [publicSite, setPublicSite] = useState(false);

  const logout = () => {
    setAuth({ user: null, token: "", refreshToken: "" });
    localStorage.removeItem("auth");
    loggedIn = false;
    navigate("/login");
  };

  const loggedIn =
    auth.user !== null && auth.token !== "" && auth.refreshToken !== "";
  const role = auth?.user?.role;
  let url;
  switch (role) {
    case "Client":
      url = "/client/inbox";
      break;
    case "Provider":
      url = "/provider/inbox";
      break;
    case "Agent":
      url = "/agent/inbox";
      break;
    case "Admin":
      url = "/admin/manage-providers";
      break;
    default:
      url = "/inbox";
      break;
  }

  const isDashboard = () => {
    const path = location.pathname;
    if (path.startsWith("/provider")) {
      setProviderDashboard(true);
      setClientDashboard(false);
      setAdminDashboard(false);
      setPublicSite(false);
    } else if (path.startsWith("/client")) {
      setProviderDashboard(false);
      setClientDashboard(true);
      setAdminDashboard(false);
      setPublicSite(false);
    } else if (path.startsWith("/admin")) {
      setProviderDashboard(false);
      setClientDashboard(false);
      setAdminDashboard(true);
      setPublicSite(false);
    } else {
      setProviderDashboard(false);
      setClientDashboard(false);
      setAdminDashboard(false);
      setPublicSite(true);
    }
  };

  useEffect(() => {
    isDashboard(); // Call the function whenever the location changes
  }, [location.pathname]); // Dependency on location.pathname ensures it runs on URL changes

  const handleLinkClick = () => {
    setOpenNav(false);
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      {publicSite && (
        <header className="bg-bgCard border-b-[1px] border-borderLightGray py-2 fixed w-full z-50 top-0">
          <div className="max-container flex-justify-between ">
            <div className="relative md:hidden">
              <div
                className="w-8 block md:hidden"
                onClick={() => setOpenNav((prev) => !prev)}
              >
                <img src={list} alt="hamburger-menu" className="w-full" />
              </div>

              <ul
                className={`absolute left-0 top-full bg-bgCard z-50 w-fit md:hidden py-4 cursor-pointer [&_li]:text-center px-8 shadow-avatar-card ${
                  openNav ? "block" : "hidden"
                }`}
                onClick={handleLinkClick}
              >
                <Link
                  to="/learn-more/client"
                  className="no-underline hover:no-underline"
                >
                  <li className="py-2 text-brownDark font-roboto-flex">
                    Clients
                  </li>
                </Link>

                <Link
                  to="/learn-more/provider"
                  className="no-underline hover:no-underline"
                >
                  <li className="py-2 text-brownDark font-roboto-flex">
                    Providers
                  </li>
                </Link>

                {/*                 {loggedIn && (
                  <>
                    <Link className="no-underline hover:no-underline" to={url}>
                      <li className="py-2 text-brownDark font-roboto-flex whitespace-nowrap">
                        My Dashboard
                      </li>
                    </Link>

                    <Link
                      className="no-underline hover:no-underline"
                      onClick={logout}
                      to="/"
                    >
                      <li className="py-2 text-brownDark font-roboto-flex">
                        Sign Out
                      </li>
                    </Link>
                  </>
                )} */}
              </ul>
            </div>
            <Logo />
            <HeaderSearchScrollView />
            <div className="menu flex items-center gap-x-6 lg:gap-x-10 xl:gap-x-14 2xl:gap-x-[40px]">
              <ul className="mb-0 p-0 hidden md:flex-align-center gap-x-6 lg:gap-x-10 xl:gap-x-14 2xl:gap-x-[40px]">
                <Link
                  to="/learn-more/client"
                  className="no-underline hover:no-underline"
                >
                  <li className="text-2xl  text-primaryBlack">Clients</li>
                </Link>
                <Link
                  to="/learn-more/provider"
                  className="no-underline hover:no-underline"
                >
                  <li className="text-2xl  text-primaryBlack">Providers</li>
                </Link>
              </ul>
              {loggedIn ? (
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle after:!hidden"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "40px",
                      }}
                    >
                      {auth.user.photo ? (
                        <Avatar
                          shape="circle"
                          size="small"
                          style={{ width: "40px", height: "40px" }}
                        />
                      ) : (
                        <Avatar
                          src={`${process.env.REACT_APP_IMAGES_PREFIX}/generic-profile-pic.png`}
                          shape="circle"
                          size="small"
                          style={{ width: "40px", height: "40px" }}
                        />
                      )}
                    </a>
                    <ul
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <Link
                          className="dropdown-item no-underline hover:no-underline"
                          to={url}
                        >
                          <li>My Dashboard</li>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item no-underline hover:no-underline"
                          onClick={logout}
                          to="/"
                        >
                          <li>Sign Out</li>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              ) : (
                <Link
                  to="/login"
                  className="text-base md:text-2xl text-primaryBlack whitespace-nowrap hover:text-primaryBlack !underline"
                >
                  Sign In
                </Link>
              )}
            </div>
          </div>
          <div className="max-container flex !py-5 lgTablet:hidden w-full  justify-center">
            <HeaderSearch />
          </div>
        </header>
      )}

      {clientDashboard && (
        <nav className="bg-slate-800 p-4 relative">
          <div className="container mx-auto flex justify-between items-center relative z-10">
            {/* Your Logo component */}
            <Logo light={true} />

            {isMobile ? (
              // Mobile view with menu icon
              <>
                <button
                  className="md:hidden text-white focus:outline-none"
                  onClick={toggleMenu}
                >
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16m-7 6h7"
                    />
                  </svg>
                </button>

                {/* Dropdown overlay */}
                {isMenuOpen && (
                  <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-20"
                    onClick={closeMenu}
                  ></div>
                )}

                {/* Dropdown menu */}
                {isMobile && (
                  <div
                    className={`md:flex items-center absolute top-full left-0 ${
                      isMenuOpen ? "block" : "hidden"
                    } md:flex-row bg-slate-800 p-4 w-full z-30`}
                  >
                    <ul className="md:flex space-x-6 md:space-x-4 m-0 p-0">
                      <li className="m-0">
                        <Link
                          to="/client/inbox"
                          className="text-white"
                          onClick={closeMenu}
                        >
                          Inbox
                        </Link>
                      </li>
                      <li className="m-0">
                        <Link
                          to="/client/engagements"
                          className="text-white"
                          onClick={closeMenu}
                        >
                          Saved Homes
                        </Link>
                      </li>
                      {/*                       <li className="m-0">
                        <Link
                          to="/client/assessment"
                          className="text-white"
                          onClick={closeMenu}
                        >
                          Care Assessment
                        </Link>
                      </li> */}
                      <li className="m-0">
                        <Link
                          to="/client/help"
                          className="text-white"
                          onClick={closeMenu}
                        >
                          Help
                        </Link>
                      </li>
                      <li className="m-0">
                        <Link
                          to="/client/profile"
                          className="text-white"
                          onClick={closeMenu}
                        >
                          Profile
                        </Link>
                      </li>
                      <li className="m-0">
                        <Link
                          to="/client/settings"
                          className="text-white"
                          onClick={closeMenu}
                        >
                          Settings
                        </Link>
                      </li>
                      <li className="m-0">
                        <Link to="/" onClick={logout} className="text-white">
                          Sign Out
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </>
            ) : (
              // Desktop view with horizontal menu
              <div className="md:flex space-x-6 md:space-x-4">
                <Link to="/client/inbox" className="text-white">
                  Inbox
                </Link>
                <Link
                  to="/client/engagements"
                  className="text-white"
                  onClick={closeMenu}
                >
                  Saved Homes
                </Link>
                {/*                 <Link
                  to="/client/assessment"
                  className="text-white"
                  onClick={closeMenu}
                >
                  Care Assessment
                </Link> */}
                <Link
                  to="/client/help"
                  className="text-white"
                  onClick={closeMenu}
                >
                  Help
                </Link>
                <Link
                  to="/client/profile"
                  className="text-white"
                  onClick={closeMenu}
                >
                  Profile
                </Link>
                <Link
                  to="/client/settings"
                  className="text-white"
                  onClick={closeMenu}
                >
                  Settings
                </Link>
                <Link to="/" onClick={logout} className="text-white">
                  Sign Out
                </Link>
              </div>
            )}
          </div>
        </nav>
      )}

      {adminDashboard && (
        <nav className="bg-red-900 p-4 relative">
          <div className="container mx-auto flex justify-between items-center relative z-10">
            {/* Your Logo component */}
            <Logo light={true} />

            {isMobile ? (
              // Mobile view with menu icon
              <>
                <button
                  className="md:hidden text-white focus:outline-none"
                  onClick={toggleMenu}
                >
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16m-7 6h7"
                    />
                  </svg>
                </button>

                {/* Dropdown overlay */}
                {isMenuOpen && (
                  <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-20"
                    onClick={closeMenu}
                  ></div>
                )}

                {/* Dropdown menu */}
                {isMobile && (
                  <div
                    className={`md:flex items-center absolute top-full left-0 ${
                      isMenuOpen ? "block" : "hidden"
                    } md:flex-row bg-red-900 p-4 w-full z-30`}
                  >
                    <ul className="md:flex space-x-6 md:space-x-4 m-0 p-0">
                      <li className="m-0">
                        <Link
                          to="/admin/manage-providers"
                          className="text-white"
                          onClick={closeMenu}
                        >
                          Assessments
                        </Link>
                      </li>
                      <li className="m-0">
                        <Link
                          to="/admin/out-of-network-requests"
                          className="text-white"
                          onClick={closeMenu}
                        >
                          OoN Requests
                        </Link>
                      </li>
                      <li className="m-0">
                        <Link to="/" onClick={logout} className="text-white">
                          Sign Out
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </>
            ) : (
              // Desktop view with horizontal menu
              <div className="md:flex space-x-6 md:space-x-4">
                <Link
                  to="/admin/manage-providers"
                  className="text-white"
                  onClick={closeMenu}
                >
                  Assessments
                </Link>
                <Link
                  to="/admin/out-of-network-requests"
                  className="text-white"
                  onClick={closeMenu}
                >
                  OoN Requests
                </Link>
                <Link to="/" onClick={logout} className="text-white">
                  Sign Out
                </Link>
              </div>
            )}
          </div>
        </nav>
      )}
    </>
  );
}

const HeaderSearchScrollView = () => {
  return (
    <div className="hidden lgTablet:flex w-full max-w-xs desktop:max-w-none justify-center">
      <HeaderSearch />
    </div>
  );
};
