import { NavLink } from "react-router-dom";
import Logo from "../../misc/Logo";
import { ReactComponent as MessageIcon } from "../../../assets/icons/messageIcon.svg";
import { ReactComponent as ListingIcon } from "../../../assets/icons/listingIcon.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settingsIcon.svg";
import { ReactComponent as SupportIcon } from "../../../assets/icons/supportIcon.svg";
import { ReactComponent as ProfileIcon } from "../../../assets/icons/profileIcon.svg";
import clsx from "clsx";

const links = [
  {
    heading: "Inbox",
    Icon: MessageIcon,
    path: "/provider/inbox",
  },
  {
    heading: "Listings",
    Icon: ListingIcon,
    path: "/provider/listings",
  },
  {
    heading: "Settings",
    Icon: SettingsIcon,
    path: "/provider/settings",
  },
  {
    heading: "Support",
    Icon: SupportIcon,
    path: "/provider/support",
  },
];

export default function ProviderLayout({ children }) {
  return (
    <div className="grid grid-rows-[12] h-screen ">
      <nav className="row-span-1 bg-white !px-5 md:!px-2.5 lg:!px-12 py-2.5 border-b border-borderLighterGray relative">
        <div className="flex justify-between items-center relative z-10">
          {/* Your Logo component */}
          <div className="w-full">
            <div className="relative w-fit left-1/2 -translate-x-2.5 md:left-0 md:-translate-x-0">
              <Logo light={true} />
            </div>
          </div>

          <div>
            <button className="bg-lightestGray rounded-full h-12 w-12 flex items-center justify-center">
              <ProfileIcon stroke="#130F26" />
            </button>
          </div>
        </div>
      </nav>

      <div className="row-span-11 flex  h-full">
        {/* Menu for device width 768px and above */}
        <div className="hidden md:block border-r border-borderLighterGray">
          <div className="flex flex-col justify-center h-full">
            {links.map(({ Icon, heading, path }) => (
              <NavLink
                to={path}
                className={({ isActive }) =>
                  clsx(
                    " flex items-center gap-2.5  border-l-4 px-6 !py-5 transition-all duration-500  hover:text-blue",
                    {
                      "text-blue border-blue": isActive,
                      "text-lighterGray  border-transparent": !isActive,
                    }
                  )
                }
              >
                <span className="h-8 w-8">
                  <Icon height={30} width={30} />
                </span>
                <span className="font-roboto-flex font-medium hidden lg:block">
                  {heading}
                </span>
              </NavLink>
            ))}
          </div>
        </div>
        {/* Menu for device width 768px and below */}
        <div className="fixed bottom-0 block md:hidden bg-white z-10 w-full shadow-provider-nav rounded-t-2xl">
          <div className="flex justify-evenly h-full">
            {links.map(({ Icon, path }) => (
              <NavLink
                to={path}
                className={({ isActive }) =>
                  clsx(
                    " flex items-center gap-2.5  px-6 !py-5 transition-all duration-500  hover:text-blue",
                    {
                      "text-blue": isActive,
                      "text-lighterGray  ": !isActive,
                    }
                  )
                }
              >
                <span className="h-8 w-8">
                  <Icon height={30} width={30} />
                </span>
              </NavLink>
            ))}
          </div>
        </div>
        <div className="w-full h-[calc(100vh-85px)] overflow-y-auto overflow-x-hidden">
          {children}
        </div>
      </div>
    </div>
  );
}
