import { SupportAccordion } from "../../components/misc/supportAccordion";
import { SupportTicketAvatar } from "../../components/misc/supportTicketAvatar";

export const ProviderSupport = () => {
  return (
    <div className="!px-5 pt-7 pb-14 space-y-20">
      <div className="flex items-center justify-center md:justify-start">
        <p className="text-borderBlack text-xl md:text-3xl font-bold">
          Support Center
        </p>
      </div>
      <div className="max-w-5xl space-y-9">
        <div className="space-y-2.5">
          <p className="m-0 text-xl font-roboto-flex">New Tickets</p>
          <div className="space-y-5">
            <div className="flex flex-col gap-3 ">
              <label htmlFor="title" className="font-roboto-flex m-0">
                Title
              </label>
              <input
                type="text"
                id="title"
                placeholder="Subject of the ticket"
                className="border border-stroke20 bg-black/5 py-2 !px-5 rounded-3xl placeholder:text-slate-400"
              />
            </div>
            <div className="flex flex-col gap-3 ">
              <label htmlFor="description" className="font-roboto-flex m-0">
                Description
              </label>
              <textarea
                rows={4}
                type="text"
                id="description"
                placeholder="Subject of the ticket"
                className="border border-stroke20 bg-black/5 py-3 !px-5 rounded-3xl placeholder:text-slate-400 resize-none"
              />
            </div>
            <div className="flex items-center justify-end">
              <button className="py-1.5 px-10 bg-blue rounded-xl text-white">
                Submit
              </button>
            </div>
          </div>
        </div>
        <div className="space-y-2.5">
          <p className="m-0 text-xl font-roboto-flex">Existing Tickets</p>
          <div>
            <div className="flex items-center justify-between border-b border-gray-300 p-3">
              <p className="m-0 font-roboto-flex">Ticket ID#</p>
              <p className="m-0 font-roboto-flex">Status</p>
            </div>
            <div>
              <div className="flex items-center justify-between border-b border-gray-200 p-3">
                <SupportTicketAvatar />
                <p className="m-0 font-roboto-flex text-gray-500">Active</p>
              </div>{" "}
              <div className="flex items-center justify-between border-b border-gray-200 p-3">
                <SupportTicketAvatar />
                <p className="m-0 font-roboto-flex text-gray-500">Active</p>
              </div>{" "}
              <div className="flex items-center justify-between border-b border-gray-200 p-3">
                <SupportTicketAvatar />
                <p className="m-0 font-roboto-flex text-gray-500">Active</p>
              </div>{" "}
              <div className="flex items-center justify-between border-b border-gray-200 p-3">
                <SupportTicketAvatar />
                <p className="m-0 font-roboto-flex text-gray-500">Active</p>
              </div>{" "}
              <div className="flex items-center justify-between border-b border-gray-200 p-3">
                <SupportTicketAvatar />
                <p className="m-0 font-roboto-flex text-gray-500">Active</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="space-y-2.5">
            <p className="m-0 text-xl font-roboto-flex">FAQs</p>
            <div className="space-y-10">
              <SupportAccordion /> <SupportAccordion /> <SupportAccordion />{" "}
              <SupportAccordion />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
