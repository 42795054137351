import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { RiUserLine } from "react-icons/ri";
import { RiEditLine } from "react-icons/ri";

import ImageUpload from "../../components/forms/ImageUploadAlt";
import { LisitingAvailability } from "../../components/misc/listingAvailabilty";
import { ListingServicesSelector } from "../../components/misc/listingServicesSelector";

const availabilityOptions = [
  {
    name: "Private Bed, Private Bath, Private Shower",
    id: "pd-pb-ps",
    value: "pR_pB_pS",
  },
  {
    name: "Private Bed, Private Bath, Shared Shower",
    id: "pd-pb-ss",
    value: "pR_pB_sS",
  },
  {
    name: " Private Bed, Shared Bath, Shared Shower",
    id: "pd-sb-ss",
    value: "pR_sB_sS",
  },
  {
    name: " Shared Bed, Shared Bath, Shared Shower:",
    id: "sd-sb-ss",
    value: "sR_sB_sS",
  },
];

const amenities = [
  {
    label: "Activity Calendar",
    id: "activityCalendar",
    value: "activityCalendar",
  },
  {
    label: "Arts, Crafts, & Games",
    id: "artsCraftsGames",
    value: "artsCraftsGames",
  },
  {
    label: "Birthday Celebrations",
    id: "birthdayCelebrations",
    value: "birthdayCelebrations",
  },
  {
    label: "Television",
    id: "television",
    value: "television",
  },
  {
    label: "Cable",
    id: "cable",
    value: "cable",
  },
  {
    label: "Call Button",
    id: "callButton",
    value: "callButton",
  },
  {
    label: "Hospital/Electric Bed",
    id: "hospitalBed",
    value: "hospitalBed",
  },
  {
    label: "Hoyer Lift",
    id: "hoyerLift",
    value: "hoyerLift",
  },
  {
    label: "Wireless Internet",
    id: "wirelessInternet",
    value: "wirelessInternet",
  },
  {
    label: "Book Library",
    id: "library",
    value: "library",
  },
  {
    label: "Newspaper",
    id: "newspaper",
    value: "newspaper",
  },
  {
    label: "Outdoor Common Area",
    id: "outdoorCommonArea",
    value: "outdoorCommonArea",
  },
  {
    label: "Community Telephone",
    id: "communityTelephone",
    value: "communityTelephone",
  },
  {
    label: "Wheelchair Accessible",
    id: "wheelchairAccessible",
    value: "wheelchairAccessible",
  },
  {
    label: "Full Meals",
    id: "fullMeals",
    value: "fullMeals",
  },
  {
    label: "Snacks",
    id: "snacks",
    value: "snacks",
  },
];

const services = [
  { label: "Beautician", id: "beautician", value: "beautician" },
  { label: "Showering/Bathing", id: "bathing", value: "bathing" },
  { label: "Dressing", id: "dressing", value: "dressing" },
  { label: "Transferring", id: "transferring", value: "transferring" },
  {
    label: "Awake Night Staff",
    id: "awakeNightStaff",
    value: "awakeNightStaff",
  },
  {
    label: "24/7 Care",
    id: "twentyFourSevenCare",
    value: "twentyFourSevenCare",
  },
  { label: "Catheter Care", id: "catheter", value: "catheter" },
  { label: "Colostomy Care", id: "colostomy", value: "colostomy" },
  { label: "Dementia", id: "dementia", value: "dementia" },
  { label: "Alzhiemers", id: "alzhiemers", value: "alzhiemers" },
  {
    label: "Developmental Disabilities",
    id: "developmentalDisabilities",
    value: "developmentalDisabilities",
  },
  { label: "Diabetes", id: "diabetes", value: "diabetes" },
  { label: "Insulin", id: "insulin", value: "insulin" },
  { label: "Feed Tube Care", id: "feedTube", value: "feedTube" },
  { label: "Female Residents Only", id: "onlyFemale", value: "onlyFemale" },
  { label: "Male Residents Only", id: "onlyMale", value: "onlyMale" },
  {
    label: "Doctor Home Visits",
    id: "doctorHomeVisit",
    value: "doctorHomeVisit",
  },
  { label: "Hospice Care", id: "hospice", value: "hospice" },
  { label: "Housekeeping", id: "housekeeping", value: "housekeeping" },
  { label: "Laundry & Linen Service", id: "laundry", value: "laundry" },
  { label: "Massage Therapy", id: "massageTherapy", value: "massageTherapy" },
  {
    label: "Medicine Delivery",
    id: "medicineDelivery",
    value: "medicineDelivery",
  },
  {
    label: "Medicine Management",
    id: "medicineManagement",
    value: "medicineManagement",
  },
  { label: "Oxygen Therapy", id: "oxygenTherapy", value: "oxygenTherapy" },
  { label: "Parkinson", id: "parkinson", value: "parkinson" },
  { label: "Pet Care", id: "petCare", value: "petCare" },
  { label: "Podiatrist", id: "podiatrist", value: "podiatrist" },
  {
    label: "Physical Therapy",
    id: "physicalTherapy",
    value: "physicalTherapy",
  },
  { label: "Respite/Shortterm Care", id: "respiteCare", value: "respiteCare" },
  { label: "RN Operated", id: "rnOperated", value: "rnOperated" },
  { label: "Stroke Recovery", id: "strokeRecovery", value: "strokeRecovery" },
  {
    label: "Transportation to Doctor",
    id: "transportDoctor",
    value: "transportDoctor",
  },
  {
    label: "Transportation to Shopping",
    id: "transportShopping",
    value: "transportShopping",
  },
  {
    label: "Visual and/or Hearing Impairment Support",
    id: "visualHearing",
    value: "visualHearing",
  },
  { label: "Wound Care", id: "woundCare", value: "woundCare" },
  { label: "Wandering Management", id: "wandering", value: "wandering" },
  { label: "Combative Management", id: "combative", value: "combative" },
];

export default function EditListing() {
  // state
  const [ad, setAd] = useState({
    _id: "",
    title: "",
    published: "",
    photos: [],
    description: "",
    currentAvailibility: {},
    careCost: {},
    amenities: {},
    services: {},
    uploading: false,
    loading: false,
  });
  const [loaded, setLoaded] = useState(false);

  // hooks
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (params?.title) fetchListing();
  }, [params?.title]);

  const fetchListing = async () => {
    setLoaded(true);

    try {
      const { data } = await axios.get(`/ad/${params.title}`);

      const updatedAd = { ...data?.ad };

      if (!data?.ad?.currentAvailibility) {
        // if this is a listing that has just been claimed, the edittable fields do not exist in the record, though they are defined in the model. Therefore, we need to set them here to default values for user to modify and save.
        updatedAd.currentAvailibility = {
          pR_pB_pS: 0,
          pR_pB_sS: 0,
          pR_sB_sS: 0,
          sR_sB_sS: 0,
        };

        updatedAd.careCost.actual = {
          rangeLowAmount: null,
          rangeHighAmount: null,
          sharePublic: false,
        };

        updatedAd.amenities = {
          activityCalendar: false,
          artsCraftsGames: false,
          birthdayCelebrations: false,
          television: false,
          cable: false,
          callButton: false,
          hospitalBed: false,
          hoyerLift: false,
          wirelessInternet: false,
          library: false,
          newspaper: false,
          outdoorCommonArea: false,
          communityTelephone: false,
          wheelchairAccessible: false,
          fullMeals: false,
          snacks: false,
        };

        updatedAd.services = {
          beautician: false,
          bathing: false,
          dressing: false,
          transferring: false,
          awakeNightStaff: false,
          twentyFourSevenCare: false,
          catheter: false,
          colostomy: false,
          dementia: false,
          alzhiemers: false,
          developmentalDisabilities: false,
          diabetes: false,
          insulin: false,
          feedTube: false,
          onlyFemale: false,
          onlyMale: false,
          doctorHomeVisit: false,
          hospice: false,
          housekeeping: false,
          laundry: false,
          massageTherapy: false,
          medicineDelivery: false,
          medicineManagement: false,
          oxygenTherapy: false,
          parkinson: false,
          petCare: false,
          podiatrist: false,
          physicalTherapy: false,
          respiteCare: false,
          rnOperated: false,
          strokeRecovery: false,
          transportDoctor: false,
          transportShopping: false,
          visualHearing: false,
          woundCare: false,
          wandering: false,
          combative: false,
          additionalLanguages: "",
        };
      }

      setAd(updatedAd);

      setLoaded(false);
    } catch (error) {
      console.log(error);
      setLoaded(false);
    }
  };

  const handleSubmit = async (e) => {
    try {
      // validation
      if (!ad.photos?.length) {
        toast.error("At least 1 photo is required");
        return;
      } else if (!ad.description) {
        toast.error("Description is required");
        return;
      } else {
        // make API put request
        setAd({ ...ad, loading: true });

        const { data } = await axios.put(`/ad/${ad._id}`, ad);

        if (data?.error) {
          toast.error(data.error);
          setAd({ ...ad, loading: false });
        } else {
          toast.success("Listing updated successfully");
          setAd({ ...ad, loading: false });
          navigate("/provider/listings");
        }
      }
    } catch (err) {
      console.log(err);
      setAd({ ...ad, loading: false });
    }
  };
  console.log(ad);
  return (
    <div className="pt-7  !px-5 max-h-[calc(100vh-105px)] overflow-auto md:overflow-hidden">
      <div className="flex items-center justify-center md:justify-start">
        <p className="text-borderBlack text-xl md:text-3xl font-bold">
          Listings &gt; Edit Listing
        </p>
      </div>
      {!loaded && (
        <div className="grid grid-cols-10 gap-4 ">
          <div className="col-span-10 md:col-span-6 lg:col-span-5 pt-10 pb-20 md:pb-0  px-6 space-y-9 max-h-[calc(100vh-185px)] overflow-y-auto">
            <div className="flex items-center justify-center md:justify-start gap-3">
              <div className="relative h-16 w-16 md:h-32 md:w-32 bg-gray-200 rounded-full flex items-center justify-center">
                <RiUserLine className="h-10 w-10" />
                <button className="absolute bottom-1 right-0 flex items-center justify-center gap-2.5 bg-white rounded-[30px] shadow-listing-add-new p-1 md:p-2">
                  <RiEditLine />
                </button>
              </div>
              <p className="m-0 text-2xl font-bold">Puerto Rico</p>
            </div>

            <div className="space-y-5">
              <div>
                <input type="text" value={ad.title} className="text-xl" />
              </div>
              <div className="md:hidden">
                <ImageUpload ad={ad} setAd={setAd} />
                <p className="m-0">Max of 20 images can be added</p>
              </div>
              <div className="">
                <p className="text-sm m-0">Current Availability</p>
                {availabilityOptions.map((option) => (
                  <LisitingAvailability option={option} ad={ad} setAd={setAd} />
                ))}
              </div>

              <div className="">
                <p className="text-sm m-0">Monthly Cost Range</p>

                <div className="flex items-center gap-4 pt-2">
                  <input
                    className="border border-gray-300 w-full py-1.5 px-3 rounded "
                    placeholder="Low"
                    type="number"
                    id="low"
                    name="quantity"
                    min="0"
                    max="20000"
                    step="100"
                    value={
                      ad?.careCost?.actual?.rangeLowAmount
                        ? ad.careCost.actual.rangeLowAmount
                        : 0
                    }
                    onChange={(e) => {
                      // Create a deep copy of ad to avoid mutating the state directly
                      const updatedAd = JSON.parse(JSON.stringify(ad));

                      // Update the low value in the nested structure
                      updatedAd.careCost.actual.rangeLowAmount = Number(
                        e.target.value
                      );

                      // Update the ad state with the modified object
                      setAd(updatedAd);
                    }}
                  />
                  <input
                    className="border border-gray-300 w-full py-1.5 px-3 rounded"
                    placeholder="High"
                    type="number"
                    id="high"
                    name="quantity"
                    min="0"
                    max="30000"
                    step="100"
                    value={
                      ad?.careCost?.actual?.rangeHighAmount
                        ? ad.careCost.actual.rangeHighAmount
                        : 0
                    }
                    onChange={(e) => {
                      // Create a deep copy of ad to avoid mutating the state directly
                      const updatedAd = JSON.parse(JSON.stringify(ad));

                      // Update the low value in the nested structure
                      updatedAd.careCost.actual.rangeHighAmount = Number(
                        e.target.value
                      );

                      // Update the ad state with the modified object
                      setAd(updatedAd);
                    }}
                  />
                </div>
              </div>
              <div>
                <p className="text-sm m-0">Description</p>
                <div className="pt-2">
                  <textarea
                    rows={5}
                    placeholder="Enter listing description.."
                    className="resize-none w-full border border-slate-300 px-3 py-2 rounded-md text-sm"
                    value={ad.description}
                    onChange={(e) =>
                      setAd({ ...ad, description: e.target.value })
                    }
                  />
                </div>
              </div>

              <div>
                <p className="text-sm m-0">Amenities</p>
                <div className="pt-2">
                  <ListingServicesSelector
                    data={amenities}
                    ad={ad}
                    setAd={setAd}
                    selectedServices={Object.keys(ad.amenities).filter(
                      (key) => ad.amenities[key]
                    )}
                    sectionId="amenities"
                  />
                </div>
              </div>

              <div className="mb-3">
                <p className="text-sm m-0">Services</p>
                <div className="pt-2">
                  <ListingServicesSelector
                    data={services}
                    ad={ad}
                    setAd={setAd}
                    selectedServices={Object.keys(ad.services).filter(
                      (key) => ad.services[key]
                    )}
                    sectionId="services"
                  />
                </div>
              </div>
              <div>
                <p className="text-sm m-0">Additional languages</p>

                <div className="pt-2">
                  <textarea
                    rows={5}
                    placeholder="Enter listing description.."
                    className="resize-none w-full border shadow-none focus:!border-slate-300 !border-slate-300 px-3 py-2 rounded-md text-sm"
                    id="additionalLanguages"
                    value={ad.services.additionalLanguages}
                    onChange={(e) => {
                      // Create a copy of the currentAvailability object
                      const updatedCurrentServices = { ...ad.services };

                      // Update the specific property within the nested object
                      updatedCurrentServices.additionalLanguages =
                        e.target.value; // Update with the checkbox value

                      // Update the ad state with the modified currentAvailability object
                      setAd({ ...ad, services: updatedCurrentServices });
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  onClick={handleSubmit}
                  className="bg-blue px-7 md:px-9 py-3 text-sm rounded-xl text-white"
                  style={{ textTransform: "capitalize" }}
                  disabled={ad.loading}
                >
                  Save Listing
                </button>
              </div>
            </div>
          </div>
          <div className="hidden md:block col-span-10 md:col-span-4 lg:col-span-5 mb-3 ">
            <p className="m-0">Max of 20 images can be added</p>
            <div className="pt-2 space-y-9">
              <ImageUpload ad={ad} setAd={setAd} />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  onClick={handleSubmit}
                  className="bg-blue px-9 py-3 rounded-xl text-white"
                  style={{ textTransform: "capitalize" }}
                  disabled={ad.loading}
                >
                  Save Listing
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {loaded && <>Loading</>}
    </div>
  );
}
