import { useEffect, useState } from "react";
import axios from "axios";
import EditListingCard from "../../components/cards/EditListingCard";
import ClaimListingCard from "../../components/cards/ClaimListingCard";
import { useAuth } from "../../context/auth";
import { Navigate, Link } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import ListingAnalytics from "../../components/cards/listingAnalyticsCard";
import { IoAdd } from "react-icons/io5";

export default function ProviderListings() {
  const [auth, setAuth] = useAuth();

  const [listings, setListings] = useState({});
  const [matchedListings, setMatchedListings] = useState({});
  const [loading, setLoading] = useState(false);

  const [claimedListingsLoading, setClaimedListingsLoading] = useState(false);
  const [matchedListingsLoading, setMatchedListingsLoading] = useState(false);

  const [verificationLinkClicked, setVerificationLinkClicked] = useState(false);

  const [verificationUrl, setVerificationUrl] = useState(null);
  const [verificationStarted, setVerificationStarted] = useState(false);
  const [verificationProcessing, setVerificationProcessing] = useState(false);
  const [verificationPass, setVerificationPass] = useState(false);
  const [verificationFail, setVerificationFail] = useState(false);

  useEffect(() => {
    handleVerificationSession();
  }, []);

  // launches and updates states of identity verification
  const handleVerificationSession = async () => {
    setLoading(true);

    try {
      const { data } = await axios.post(`/identity-verification/get-url`, {
        userId: auth.user._id,
      });

      checkVerificationSession();

      if (!verificationPass) {
        if (
          data.messages[0].content ===
          "Successfully created and saved a verification session"
        ) {
          setVerificationUrl(data.data.identityVerificationUrl);
          if (data.data.status !== "verified") {
            setVerificationStarted(true);
            setVerificationProcessing(false);
            setVerificationPass(false);
            setVerificationFail(false);
          }
        }

        if (
          data.messages[0].content ===
          "Successfully retrieved a verification session"
        ) {
          setVerificationUrl(data.data.identityVerificationUrl);
          if (data.data.status !== "verified") {
            setVerificationStarted(true);
            setVerificationProcessing(false);
            setVerificationPass(false);
            setVerificationFail(false);
          }
        }

        if (
          data.messages[0].content ===
          "The session has been submitted and is being processed"
        ) {
          setVerificationStarted(false);
          setVerificationProcessing(true);
          setVerificationPass(false);
          setVerificationFail(false);
        }

        if (
          data.messages[0].content ===
          "Processing of all the verification checks are complete and successfully verified."
        ) {
          setVerificationStarted(false);
          setVerificationProcessing(false);
          setVerificationPass(true);
          setVerificationFail(false);
          checkVerificationSession();
          fetchClaimedListings();
          fetchMatchedListings();
        }

        if (
          data.messages[0].content ===
          "Processing of all the verification checks are complete and user is unverified"
        ) {
          setVerificationStarted(false);
          setVerificationProcessing(false);
          setVerificationPass(false);
          setVerificationFail(true);
          checkVerificationSession();
        }
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleWaitingSession = async () => {
    setVerificationStarted(false);
    setVerificationProcessing(true);
    setVerificationPass(false);
    setVerificationFail(false);

    const checkVerification = async () => {
      await checkVerificationSession();

      if (!verificationProcessing) {
        setTimeout(checkVerification, 5000);
      }
    };

    checkVerification();

    // Function to clear the interval when the user exits the page
    const clearVerificationInterval = () => {
      // Additional cleanup logic if needed
    };

    // Attach the clearVerificationInterval function to the beforeunload event
    window.addEventListener("beforeunload", clearVerificationInterval);
  };

  // retrieves user data & uploads auth
  const checkVerificationSession = async () => {
    try {
      const { data } = await axios.get(
        `/refresh-identity-verification-results`
      );

      if (auth.user !== data) {
        // update user in context
        setAuth({ ...auth, user: data });

        // update user in local storage
        const fromLS = JSON.parse(localStorage.getItem("auth"));
        fromLS.user = data;

        localStorage.setItem("auth", JSON.stringify(fromLS));

        if (
          data?.identity?.stripe?.verificationStatus === "unverified" &&
          data?.identity?.stripe?.error?.reason
        ) {
          setVerificationStarted(false);
          setVerificationProcessing(false);
          setVerificationPass(false);
          setVerificationFail(true);
        }

        if (data?.identity?.stripe?.verificationStatus === "verified") {
          setVerificationStarted(false);
          setVerificationProcessing(false);
          setVerificationPass(true);
          setVerificationFail(false);
          fetchClaimedListings();
          fetchMatchedListings();

          return;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchClaimedListings = async () => {
    setClaimedListingsLoading(true);

    try {
      const { data } = await axios.get(`/claimed-listings/read`);
      setListings(data.listings);

      setClaimedListingsLoading(false);
    } catch (error) {
      console.log(error);
      setClaimedListingsLoading(false);
    }
  };

  const fetchMatchedListings = async () => {
    setMatchedListingsLoading(true);

    try {
      const { data } = await axios.post(`/listings/match/to-user`, {
        userId: auth.user._id,
      });

      // Chain the filters
      const filteredListings = data.data.matches
        .filter((item) => item.listing.subscriptionType !== "free")
        .filter((item) => item.listing.subscriptionType !== "premium");

      setMatchedListings(filteredListings);

      setMatchedListingsLoading(false);
    } catch (error) {
      console.log(error);
      setMatchedListingsLoading(false);
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center vh-100"
        style={{ marginTop: "-10%" }}
      >
        <div className="display-4">Loading...</div>
      </div>
    );
  }

  return (
    <div className="">
      <div className="py-6 px-4">
        {verificationStarted && (
          <div
            className="container text-center mt-2 mb-5"
            style={{ padding: "1%" }}
          >
            <h5 className="display-4 text-dark mb-5">Verify Identity</h5>
            <p>!!! IMPORTANT READ BEFORE PROCEEDING !!! </p>
            <p className="mb-5">
              In order to protect listings from being fradulently claimed and
              provide clients with direct access to licensed providers, we
              require every provider to verify their legal identity. The person
              who completes this verification process must be the same person
              who is on the license from the state. For example, if your spouse
              is the one who's name is on the license, they must complete the
              verification process. Their name and their address on their
              license must result in a high confidence score in order to find
              the Senior Care homes that they operate. You will only get 1 free
              attempt to Verify and claim your listing(s). Any additional
              attempts will cost $4.50 per each attempt and will require you to
              submit a customer support ticket(which may take 24hrs to process).
            </p>
            <a
              href={verificationUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary"
              onClick={handleWaitingSession}
              style={{ textTransform: "capitalize" }}
            >
              Verify
            </a>
          </div>
        )}

        {verificationProcessing && (
          <div
            className="container text-center mt-2 mb-5"
            style={{ padding: "1%" }}
          >
            <h5 className="display-4 text-dark mb-5">
              Verification in Progress
            </h5>
            <p className="mb-5">
              If you've just completed your session, please wait for the results
              to load as they may take up to a minute to be received from Stripe
              Identity.
            </p>
            <p className="mb-5">
              If you haven't completed your session and need to access the link,
              please refresh this page and try again.
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "80px",
              }}
            >
              <Circles
                height="60"
                width="60"
                color="#000000"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
            {/* <button onClick={handleVerificationSession} className='btn btn-primary' style={{ textTransform: "capitalize" }}>Refresh</button> */}
          </div>
        )}

        {verificationPass && (
          <>
            <div className="space-y-6">
              <div>
                <h5 className="text-dark font-bold text-3xl">Listings</h5>
                <p className="m-0 max-w-2xl font-roboto-flex">
                  Listings are automatically added based on your identity
                  information. If you operate another facility that is not
                  showing here, please click the{" "}
                  <span className="text-blue font-medium">‘Add new’</span>{" "}
                  button below.
                </p>
              </div>

              <div>
                {!claimedListingsLoading && listings && listings.length > 0 && (
                  <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 xl:gap-7">
                    <ListingAnalytics />
                    {listings.map((ad) => (
                      <EditListingCard
                        ad={ad}
                        user={auth.user._id}
                        key={ad._id}
                      />
                    ))}
                  </div>
                )}
              </div>
              <div>
                {claimedListingsLoading && <p>Loading Claimed Listings..</p>}
                {!claimedListingsLoading &&
                  (!listings || !listings.length > 0) && (
                    <p>No Claimed Listings</p>
                  )}
              </div>
            </div>
          </>
        )}

        {verificationFail && (
          <div
            className="container text-center mt-2 mb-5"
            style={{ padding: "1%" }}
          >
            <h5 className="display-4 text-dark mb-5">Verification Failed</h5>
            <p className="mb-5">
              Please contact customer support for further assistance.
            </p>
            <Link
              to="/provider/help"
              className="btn btn-primary"
              style={{ textTransform: "capitalize" }}
            >
              Contact Support
            </Link>
          </div>
        )}
      </div>
      <button className="flex items-center justify-center gap-2.5 bg-white rounded-[30px] shadow-listing-add-new py-3 px-8 text-blue text-lg font-semibold fixed bottom-9 left-1/2 -translate-x-1/2">
        <IoAdd />
        Add New
      </button>
    </div>
  );
}
