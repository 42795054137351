import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useAuth } from "../../context/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import { format } from "date-fns";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import Provider_MessagerCardContacts from "../cards/Provider_MessagerCardContacts";
import Provider_MessagerCardSingleThread from "../cards/Provider_MessagerCardSingleThread";
import CurrentAssessmentForm from "./CurrentAssessmentForm";
import { ReactComponent as FilterIcon } from "../../assets/icons/filterIcon.svg";
import { ReactComponent as StarFilledIcon } from "../../assets/icons/starFilledIcon.svg";
import { ReactComponent as EyeIcon } from "../../assets/icons/eyeIcon.svg";
import { ReactComponent as SendIcon } from "../../assets/icons/sendIcon.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/profileIcon.svg";
import { MdContentPaste } from "react-icons/md";
import { BsChevronLeft } from "react-icons/bs";

import { MdPlaylistAdd } from "react-icons/md";
import { HiDotsVertical } from "react-icons/hi";

export default function Provider_FullMessager() {
  // context
  const [auth] = useAuth();

  // state
  const isSmallScreen = useMediaQuery({ query: "(max-width: 775px)" }); // Adjust the breakpoint as needed
  const [threadOpen, setThreadOpen] = useState(false);

  const [message, setMessage] = useState();
  const [listingId, setListingId] = useState();
  const [receivedBy, setRecievedBy] = useState();

  // holds the engagement state with all it's fields
  const [engagement, setEngagement] = useState();

  // states of share assessment feature
  const [assessmentShared, setAssessmentShared] = useState(false);
  const [assessmentRequested, setAssessmentRequested] = useState(false);
  const [assessmentViewed, setAssessmentViewed] = useState(false);
  const [assessmentCompatible, setAssessmentCompatible] = useState(false);
  const [notCompatibleFeedback, setNotCompatibleFeedback] = useState(false);
  const [submitPublicFeedback, setSubmitPublicFeedback] = useState();
  const [submitPrivateFeedback, setSubmitPrivateFeedback] = useState();
  const [isCurrentAssessmentOpen, setIsCurrentAssessmentOpen] = useState(false);

  const [assessment, setAssessment] = useState();

  // states of tour feature
  const [isTourFeatureOpen, setisTourFeatureOpen] = useState(false);
  const [tourRequestedStatus, setTourRequestedStatus] = useState(false);
  const [tourOfferClientStatus, setTourOfferClientStatus] = useState(false);
  const [tourOfferClientSchedule, setTourOfferClientSchedule] = useState();
  const [tourOfferProviderStatus, setTourOfferProviderStatus] = useState(false);
  const [tourOfferProviderSchedule, setTourOfferProviderSchedule] = useState();
  const [tourAcceptedStatus, setTourAcceptedStatus] = useState(false);
  const [tourAcceptedSchedule, setTourAcceptedSchedule] = useState();
  const [tourCompletedStatus, setTourCompletedStatus] = useState(false);
  const [tourReviewAcceptedPrivateStatus, setTourReviewAcceptedPrivateStatus] =
    useState(false);
  const [
    tourReviewAcceptedPrivateFeedback,
    setTourReviewAcceptedPrivateFeedback,
  ] = useState();
  const [tourReviewAcceptedPublicStatus, setTourReviewAcceptedPublicStatus] =
    useState(false);
  const [
    tourReviewAcceptedPublicFeedback,
    setTourReviewAcceptedPublicFeedback,
  ] = useState();

  const [selectedDate, setSelectedDate] = useState(null);
  const [requestAlternativeTourDates, setRequestAlternativeTourDates] =
    useState(false);

  // tour feature provider counter response variables
  const [selectedSlots, setSelectedSlots] = useState({});
  const [newDate, setNewDate] = useState(new Date());

  const [allThreads, setAllThreads] = useState();
  const [currentThread, setCurrentThread] = useState();

  const [loading, setLoading] = useState();
  const [ad, setAd] = useState();

  // hooks
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const containerRef = useRef(null);

  const loggedIn = auth.user !== null && auth.token !== ""; // returns true if user is logged in and session is valid
  // use something similar for paid accounts and additional features, for both client and owner

  const handleBack = () => {
    navigate("/provider/inbox");
    setThreadOpen(false);
  };

  // fetches all different conversations(relationships) which include the logged in user
  useEffect(() => {
    fetchAllThreads();
  }, [auth.token !== ""]);

  const fetchAllThreads = async () => {
    setLoading(true);

    try {
      const { data } = await axios.get("/get-all-threads");
      setAllThreads(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // checks for whether specific thread is selected by user and gathers all messages in that thread
  useEffect(() => {
    if (searchParams.get("thread")) {
      fetchThread();
      setThreadOpen(true);
    }
  }, [searchParams]);

  useLayoutEffect(() => {
    // Ensure the container opens up at the bottom by setting scrollTop to the maximum value
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [currentThread]);

  const fetchThread = async () => {
    setLoading(true);

    try {
      const { data } = await axios.get(
        `/get-single-thread/${searchParams.get("thread")}`
      );

      setCurrentThread(data);
      setListingId(data[0]?.listingId);

      // check user type
      const userType = await axios.get(`/get-user-type/${auth.user._id}`);

      // maps out conversation left & right
      if (userType?.data?.role === "Client") {
        setRecievedBy(data[0]?.receivedBy);
      }
      if (userType?.data?.role === "Provider") {
        setRecievedBy(data[0]?.postedBy);
      }

      // fetch unique engagement
      const data1 = await axios.get(
        `/get-engagement/${searchParams.get("thread")}`
      );
      setEngagement(data1?.data);

      setAssessmentShared(data1?.data?.assessmentShared_status);
      setAssessmentRequested(data1?.data?.assessmentRequested_status);
      setAssessmentViewed(data1?.data?.assessmentViewed_status);
      setAssessmentCompatible(data1?.data?.assessmentCompatible_status);

      setTourRequestedStatus(data1?.data?.tourRequested_status);
      setTourOfferClientStatus(data1?.data?.tourOfferClient_status);
      setTourOfferClientSchedule(data1?.data?.tourOfferClient_schedule);
      setTourOfferProviderStatus(data1?.data?.tourOfferProvider_status);
      setTourOfferProviderSchedule(data1?.data?.tourOfferProvider_schedule);
      setTourAcceptedStatus(data1?.data?.tourAccepted_status);
      setTourAcceptedSchedule(data1?.data?.tourAccepted_schedule);
      setTourCompletedStatus(data1?.data?.tourCompleted_status);
      setTourReviewAcceptedPrivateStatus(
        data1?.data?.tourReviewAcceptedPrivate_status
      );
      setTourReviewAcceptedPrivateFeedback(
        data1?.data?.tourReviewAcceptedPrivate_feedback
      );
      setTourReviewAcceptedPublicStatus(
        data1?.data?.tourReviewAcceptedPublic_status
      );
      setTourReviewAcceptedPublicFeedback(
        data1?.data?.tourReviewAcceptedPublic_feedback
      );

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // fetches assessment of client thread in focus if assessment has been shared
  useEffect(() => {
    if (assessmentShared === true) fetchAssessment();
  }, [assessmentShared]);

  const fetchAssessment = async () => {
    setLoading(true);

    try {
      const { data } = await axios.get(
        `/current-care-assessment/${engagement?.assessmentShared_id}`
      );

      setAssessment(data?.assessment);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // handles direct messages submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data } = await axios.post("/new-message", {
        receivedBy,
        listingId,
        message,
      });

      if (data?.error) {
        toast.error(data?.error);
        setLoading(false);
      } else {
        toast.success("Message Sent");
        setMessage("");
        setLoading(false);
        fetchThread();
        fetchAllThreads();
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong. Try again");
      setLoading(false);
    }
  };

  // provider actioned request for client to share their assessment
  const requestAssessment = async () => {
    setLoading(true);

    try {
      const { data } = await axios.post("/request-assessment", {
        receivedBy,
        listingId,
      });

      if (data?.error) {
        toast.error(data?.error);
        setLoading(false);
      } else {
        toast.success("Care Assessment Requested");
        fetchThread();
        fetchAllThreads();
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong. Try again");
      setLoading(false);
    }
  };

  // toggles the view for reading a shared assessment. If first time being viewed, this calls the function for notifying all parties that the assessment was viewed
  const toggleViewAssessment = () => {
    setIsCurrentAssessmentOpen(!isCurrentAssessmentOpen);
    if (!assessmentViewed) assessmentRead();
  };

  // this marks the engagement field for viewed assessment as true, notifies both parties that assessment was viewed
  const assessmentRead = async () => {
    setLoading(true);

    try {
      const { data } = await axios.post("/view-assessment", {
        receivedBy,
        listingId,
      });

      if (data?.error) {
        setLoading(false);
      } else {
        fetchThread();
        fetchAllThreads();
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong. Try again");
      setLoading(false);
    }
  };

  // this allows feedback for noncompatible selection
  const toggleNotCompatible = async () => {
    setNotCompatibleFeedback(!notCompatibleFeedback);
  };

  const handleSubmitNotCompatible = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data } = await axios.post("/relationship-not-compatible", {
        receivedBy,
        listingId,
        submitPublicFeedback,
        submitPrivateFeedback,
      });

      if (data?.error) {
        toast.error(data?.error);
        setLoading(false);
      } else {
        fetchThread();
        fetchAllThreads();
        setSubmitPublicFeedback("");
        setSubmitPrivateFeedback("");
        setLoading(false);
        toggleNotCompatible();
        setIsCurrentAssessmentOpen(!isCurrentAssessmentOpen);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong. Try again");
      setLoading(false);
    }
  };

  const handleSubmitIsCompatible = async () => {
    setLoading(true);

    try {
      const { data } = await axios.post("/relationship-is-compatible", {
        receivedBy,
        listingId,
      });

      if (data?.error) {
        toast.error(data?.error);
        setLoading(false);
      } else {
        fetchThread();
        fetchAllThreads();
        setLoading(false);
        setIsCurrentAssessmentOpen(!isCurrentAssessmentOpen);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong. Try again");
      setLoading(false);
    }
  };

  // toggles the view for responding to a tour
  const toggleViewTour = () => {
    setisTourFeatureOpen(!isTourFeatureOpen);
  };

  // for formatting dates & times from client tour offer to visually render nicely to user
  function formatDateForDisplay(datetimeStr) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const dateObj = new Date(datetimeStr.replace("T", " "));

    const day = dateObj.getDate();
    const monthIndex = dateObj.getMonth();
    const year = dateObj.getFullYear();
    const hours24 = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const hours12 = hours24 % 12 || 12; // Convert to 12-hour format and handle the case for 12 noon/midnight
    const ampm = hours24 >= 12 ? "PM" : "AM";
    const formattedTime = `${hours12}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;

    return `${monthNames[monthIndex]} ${day}, ${year} at ${formattedTime}`;
  }

  const handleSubmitTourResponse_Accept = async () => {
    if (!selectedDate) {
      toast.error("Please select a time slot offered by the Client!");
    } else {
      setLoading(true);

      try {
        const engagementId = engagement?._id;

        const { data } = await axios.post("/accept-tour-request", {
          engagementId,
          selectedDate,
        });

        if (data?.error) {
          toast.error(data?.error);
          setLoading(false);
        } else {
          toast.success("Tour Date & Time Accepted!");
          fetchThread();
          fetchAllThreads();
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong. Try again");
        setLoading(false);
      }
    }
  };

  const toggleSubmitTourResponse_ProviderResponse = async () => {
    setRequestAlternativeTourDates(!requestAlternativeTourDates);
  };

  const handleTourRequestAlternativeTimeSubmit = async () => {
    const trueValuesCount = Object.values(selectedSlots).filter(
      (value) => value === true
    ).length;

    if (trueValuesCount < 3) {
      toast.error("Please select at least 3 time slots.");
    } else {
      setLoading(true);

      try {
        const engagementId = engagement?._id;

        const { data } = await axios.post(
          "/provider-alternative-tour-request",
          {
            engagementId,
            selectedSlots,
          }
        );

        if (data?.error) {
          toast.error(data?.error);
          setLoading(false);
        } else {
          toast.success("Alternative tour times & dates sent to Client.");
          fetchThread();
          fetchAllThreads();
          toggleViewTour();
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong. Try again");
        setLoading(false);
      }
    }
  };

  const handleAddNewDateTime = () => {
    const key = format(newDate, "yyyy-MM-dd'T'HH:mm");
    setSelectedSlots((prevState) => ({ ...prevState, [key]: true }));
  };

  const removeSelectedDate = (key) => {
    setSelectedSlots((prevState) => ({ ...prevState, [key]: false }));
  };
  return (
    <div className="h-full">
      {isSmallScreen ? (
        <div className="mb-20">
          {threadOpen ? (
            <div>
              <div>
                <ThreadsHeader handleBack={handleBack} />
              </div>
              <div
                className="space-y-2 py-2 !px-5 h-[70vh] overflow-y-auto"
                ref={containerRef}
              >
                {currentThread?.map((currentThread) => (
                  // eslint-disable-next-line react/jsx-pascal-case
                  <Provider_MessagerCardSingleThread
                    currentThread={currentThread}
                    key={currentThread._id}
                  />
                ))}
              </div>
              <div className="!px-5">
                <form onSubmit={handleSubmit}>
                  <div className="flex items-center justify-between !px-5 py-2.5 rounded-xl border border-gray-300 overflow-hidden">
                    <input
                      name="message"
                      className="font-roboto-flex w-full text-sm"
                      placeholder="Type a Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      disabled={!loggedIn}
                    />

                    <button disabled={loading}>
                      <SendIcon />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <div>
              <ThreadsListHeader />
              {allThreads?.map((allThreads) => (
                // eslint-disable-next-line react/jsx-pascal-case
                <Provider_MessagerCardContacts
                  allThreads={allThreads}
                  key={allThreads._id}
                  activeThread={searchParams.get("thread")}
                />
              ))}
            </div>
          )}
        </div>
      ) : (
        <div className="grid grid-cols-12 h-full">
          {/* this column displays all the unique persons user has engaged with via messaging */}
          <div className="md:col-span-5 lg:col-span-4 md:px-2 lg:px-4">
            <ThreadsListHeader />
            <div className="h-[calc(100vh-176px)] overflow-y-auto">
              {allThreads?.map((allThreads) => (
                // eslint-disable-next-line react/jsx-pascal-case
                <Provider_MessagerCardContacts
                  allThreads={allThreads}
                  key={allThreads._id}
                  activeThread={searchParams.get("thread")}
                />
              ))}{" "}
            </div>
          </div>

          {/* this column displays the unique conversation of selected user */}
          {searchParams.get("thread") && (
            <div className="md:col-span-7 lg:col-span-8 !px-6 h-full">
              <div>
                <ThreadsHeader handleBack={handleBack} />
              </div>
              <div
                className="space-y-2 py-2 h-[70vh] overflow-y-auto"
                ref={containerRef}
              >
                {currentThread?.map((currentThread) => (
                  // eslint-disable-next-line react/jsx-pascal-case
                  <Provider_MessagerCardSingleThread
                    currentThread={currentThread}
                    key={currentThread._id}
                  />
                ))}
              </div>
              <div>
                <form onSubmit={handleSubmit}>
                  <div className="flex items-center justify-between !px-5 py-2.5 rounded-xl border border-gray-300 overflow-hidden">
                    <input
                      name="message"
                      className="font-roboto-flex w-full text-sm"
                      placeholder="Type a Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      disabled={!loggedIn}
                    />

                    <button disabled={loading}>
                      <SendIcon />
                    </button>
                  </div>
                </form>

                <div className="row mt-3 ">
                  {/* Care Assessment */}
                  <div className="col">
                    {assessmentShared ? (
                      <a
                        className="btn btn-primary text-capitalize"
                        onClick={toggleViewAssessment}
                      >
                        {isCurrentAssessmentOpen ? (
                          <div
                            style={{
                              position: "fixed",
                              top: "0",
                              left: "0",
                              height: "100vh",
                              width: "100vw",
                              backgroundColor: "rgba(0,0,0,0.7)",
                              cursor: "default",
                            }}
                            onClick={toggleViewAssessment}
                          >
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{
                                width: "100%",
                                height: "75vh", // 50% of the viewport height
                                padding: "5px", // Add padding as needed
                              }}
                            >
                              <div
                                className="card text-center"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <div className="card-header text-primary">
                                  Client's Care Assessment
                                </div>

                                <div
                                  className="card-body"
                                  style={{
                                    overflowY: "auto",
                                    maxHeight: "calc(50vh - 80px)",
                                  }}
                                >
                                  <CurrentAssessmentForm
                                    assessment={assessment}
                                  />
                                </div>

                                <div className="card-footer text-muted">
                                  {assessmentCompatible === null ? (
                                    <div>
                                      Is this Client compatible with your
                                      facility?
                                      <a
                                        class="btn btn-success ml-4 text-capitalize"
                                        onClick={handleSubmitIsCompatible}
                                      >
                                        Yes
                                      </a>
                                      <a
                                        class="btn btn-danger ml-4 text-capitalize"
                                        onClick={toggleNotCompatible}
                                      >
                                        {notCompatibleFeedback ? (
                                          <div
                                            style={{
                                              position: "fixed",
                                              top: "0",
                                              left: "0",
                                              height: "100vh",
                                              width: "100vw",
                                              backgroundColor:
                                                "rgba(0,0,0,0.7)",
                                              cursor: "default",
                                            }}
                                            onClick={toggleNotCompatible}
                                          >
                                            <div
                                              className="d-flex justify-content-center align-items-center"
                                              style={{
                                                width: "100%",
                                                height: "75vh", // 50% of the viewport height
                                                padding: "5px", // Add padding as needed
                                              }}
                                            >
                                              <div
                                                className="card text-center"
                                                onClick={(e) =>
                                                  e.stopPropagation()
                                                }
                                              >
                                                <div className="card-header text-primary">
                                                  Submit Feedback
                                                </div>
                                                <div className="card-body">
                                                  <p className="card-text text-info">
                                                    Why is this Client not
                                                    compatible with your
                                                    facility?
                                                  </p>
                                                  <form
                                                    onSubmit={
                                                      handleSubmitNotCompatible
                                                    }
                                                  >
                                                    <input
                                                      type="text"
                                                      name="message"
                                                      className="form-control mb-2"
                                                      placeholder="Public Feedback - Shared with Client"
                                                      value={
                                                        submitPublicFeedback
                                                      }
                                                      onChange={(e) =>
                                                        setSubmitPublicFeedback(
                                                          e.target.value
                                                        )
                                                      }
                                                      disabled={!loggedIn}
                                                    ></input>

                                                    <input
                                                      type="text"
                                                      name="message"
                                                      className="form-control mb-2"
                                                      placeholder="Private Feedback - Not shared with Client"
                                                      value={
                                                        submitPrivateFeedback
                                                      }
                                                      onChange={(e) =>
                                                        setSubmitPrivateFeedback(
                                                          e.target.value
                                                        )
                                                      }
                                                      disabled={!loggedIn}
                                                    ></input>

                                                    <button
                                                      className="btn btn-primary mt-2 pointer"
                                                      disabled={loading}
                                                    >
                                                      {loading
                                                        ? "Please Wait"
                                                        : "Submit"}
                                                    </button>
                                                  </form>
                                                </div>
                                                <div className="card-footer text-muted">
                                                  SeniorCareHomes.Org
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <>No</>
                                        )}
                                      </a>
                                    </div>
                                  ) : (
                                    <div>
                                      {assessmentCompatible === true ? (
                                        <p>
                                          Is this Client compatible with your
                                          facility? Yes
                                        </p>
                                      ) : (
                                        <p>
                                          Is this Client compatible with your
                                          facility? No
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          "Assessment"
                        )}
                      </a>
                    ) : (
                      <>
                        {assessmentRequested ? (
                          <a className="btn btn-success text-capitalize">
                            Assessment Requested
                          </a>
                        ) : (
                          <a
                            className="btn btn-primary text-capitalize"
                            onClick={requestAssessment}
                          >
                            Request Assessment
                          </a>
                        )}
                      </>
                    )}
                  </div>

                  <div className="col text-capitalize">
                    {tourRequestedStatus ? (
                      <a
                        className="btn btn-primary text-capitalize"
                        onClick={toggleViewTour}
                      >
                        {isTourFeatureOpen ? (
                          <div
                            style={{
                              position: "fixed",
                              top: "0",
                              left: "0",
                              height: "100vh",
                              width: "100vw",
                              backgroundColor: "rgba(0,0,0,0.7)",
                              cursor: "default",
                            }}
                            onClick={toggleViewTour}
                          >
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{
                                width: "100%",
                                height: "75vh", // 50% of the viewport height
                                padding: "5px", // Add padding as needed
                              }}
                            >
                              <div
                                className="card text-center"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <div className="card-header text-primary text-capitalize">
                                  Tour Request
                                </div>
                                {tourAcceptedStatus ? (
                                  <>
                                    <div className="card-body text-secondary">
                                      <p>Tour Scheduled</p>
                                      {formatDateForDisplay(
                                        tourAcceptedSchedule[0]
                                      )}
                                    </div>
                                    <div className="card-footer text-muted">
                                      <p>SeniorCareHomes.org</p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {tourOfferProviderStatus ? (
                                      <>
                                        <div className="card-body text-secondary">
                                          <p>
                                            Waiting on Client to accept your
                                            alternative tour date & time.
                                          </p>
                                        </div>
                                        <div className="card-footer text-muted">
                                          <p>SeniorCareHomes.org</p>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="card-body text-secondary">
                                          <p>
                                            Client's Requested Dates & Time:
                                          </p>
                                          {tourOfferClientSchedule.map(
                                            (datetime) => (
                                              <div className="row mb-1">
                                                <button
                                                  key={datetime}
                                                  className={`btn ${
                                                    selectedDate === datetime
                                                      ? "btn-success"
                                                      : "btn-light"
                                                  }`}
                                                  onClick={() =>
                                                    setSelectedDate(datetime)
                                                  }
                                                >
                                                  {formatDateForDisplay(
                                                    datetime
                                                  )}
                                                </button>
                                              </div>
                                            )
                                          )}
                                        </div>
                                        <div className="card-footer text-muted">
                                          <div className="row mb-3">
                                            <button
                                              className="btn btn-success text-capitalize"
                                              onClick={
                                                handleSubmitTourResponse_Accept
                                              }
                                            >
                                              Accept
                                            </button>
                                          </div>
                                          <div className="row mb-3">
                                            <a
                                              className="btn btn-warning text-capitalize"
                                              onClick={
                                                toggleSubmitTourResponse_ProviderResponse
                                              }
                                            >
                                              {requestAlternativeTourDates ? (
                                                <div
                                                  style={{
                                                    position: "fixed",
                                                    top: "0",
                                                    left: "0",
                                                    height: "100vh",
                                                    width: "100vw",
                                                    backgroundColor:
                                                      "rgba(0,0,0,0.7)",
                                                    cursor: "default",
                                                  }}
                                                  onClick={
                                                    toggleSubmitTourResponse_ProviderResponse
                                                  }
                                                >
                                                  <div
                                                    className="d-flex justify-content-center align-items-center"
                                                    style={{
                                                      width: "100%",
                                                      height: "75vh", // 50% of the viewport height
                                                      padding: "5px", // Add padding as needed
                                                    }}
                                                  >
                                                    <div
                                                      className="card text-center"
                                                      onClick={(e) =>
                                                        e.stopPropagation()
                                                      }
                                                    >
                                                      <div className="card-header text-primary">
                                                        Suggest Different Date &
                                                        Time
                                                      </div>
                                                      <div className="card-body text-secondary">
                                                        <h3>
                                                          Choose your
                                                          availability
                                                        </h3>

                                                        <p>
                                                          Please select 3 or
                                                          more available dates &
                                                          times to tour.
                                                        </p>

                                                        <DateTimePicker
                                                          onChange={setNewDate}
                                                          value={newDate}
                                                        />

                                                        <button
                                                          className="btn btn-warning text-capitalize ml-2 mb-1"
                                                          onClick={
                                                            handleAddNewDateTime
                                                          }
                                                        >
                                                          Add
                                                        </button>

                                                        <>
                                                          {Object.values(
                                                            selectedSlots
                                                          ).filter(
                                                            (value) =>
                                                              value === true
                                                          ).length !== 0 && (
                                                            <>
                                                              <h5 className="mt-4">
                                                                Selected Times
                                                              </h5>
                                                              <p>
                                                                Click to Remove
                                                                from List
                                                              </p>
                                                              {Object.entries(
                                                                selectedSlots
                                                              )
                                                                .filter(
                                                                  ([
                                                                    datetime,
                                                                    value,
                                                                  ]) =>
                                                                    value ===
                                                                    true
                                                                )
                                                                .map(
                                                                  ([
                                                                    datetime,
                                                                    value,
                                                                  ]) => (
                                                                    <div
                                                                      className="row mb-1"
                                                                      key={
                                                                        datetime
                                                                      }
                                                                    >
                                                                      <button
                                                                        className="btn btn-light"
                                                                        onClick={() =>
                                                                          removeSelectedDate(
                                                                            datetime
                                                                          )
                                                                        }
                                                                      >
                                                                        {formatDateForDisplay(
                                                                          datetime
                                                                        )}
                                                                      </button>
                                                                    </div>
                                                                  )
                                                                )}
                                                              <div className="mb-4"></div>
                                                            </>
                                                          )}
                                                        </>
                                                      </div>

                                                      <div className="card-footer text-muted">
                                                        <div className="row">
                                                          <a
                                                            className="btn btn-success"
                                                            onClick={
                                                              handleTourRequestAlternativeTimeSubmit
                                                            }
                                                          >
                                                            Submit Request
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                "Request Alternative Time"
                                              )}
                                            </a>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          "Tour Request"
                        )}
                      </a>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const ThreadsListHeader = () => {
  return (
    <div className="flex items-center justify-between py-3 px-4 md:border-b md:border-gray-300">
      <div className="w-full">
        <p className="m-0 text-center text-3xl font-bold translate-x-2.5">
          Inbox
        </p>
      </div>

      <ul className="navbar-nav ms-auto">
        <li className="nav-item dropdown">
          <button
            className=" dropdown-toggle after:!hidden rounded-full p-2 shadow-lg"
            id="navbarDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FilterIcon height={24} width={24} />
          </button>
          <ul
            className="dropdown-menu dropdown-menu-end py-0 rounded-xl border border-gray-600 shadow-none"
            aria-labelledby="navbarDropdown"
          >
            <li className="flex items-center justify-between p-1.5">
              <p className="m-0 text-sm">Starred</p>
              <div>
                <StarFilledIcon />
              </div>
            </li>
            <li className="flex items-center justify-between p-1.5">
              <p className="m-0 text-sm">Archived</p>
              <div>
                <EyeIcon />
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

const ThreadsHeader = ({ handleBack }) => {
  return (
    <div className="flex items-center justify-between py-4 px-3 border-b border-gray-300">
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2">
          <button className="lg:hidden h-10" onClick={handleBack}>
            <BsChevronLeft />
          </button>
          <ProfileIcon fill="#3C6E7180" stroke="#3C6E7180" />
        </div>
        <p className="m-0 text-xl font-bold text-gray-500">List Item</p>
      </div>
      <div className="flex items-center !gap-5">
        <MdContentPaste className="h-7 w-7" />
        <MdPlaylistAdd className="h-7 w-7" />
        <ul className="navbar-nav ms-auto">
          <li className="nav-item dropdown">
            <button
              className=" dropdown-toggle after:!hidden "
              id="navbarDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <HiDotsVertical className="h-7 w-7 text-blue" />
            </button>
            <ul
              className="dropdown-menu dropdown-menu-end py-0 rounded-xl border border-gray-600 shadow-none"
              aria-labelledby="navbarDropdown"
            >
              <li className="flex items-center justify-between p-1.5">
                <p className="m-0 text-sm">Star</p>
                <div>
                  <StarFilledIcon />
                </div>
              </li>
              <li className="flex items-center justify-between p-1.5">
                <p className="m-0 text-sm">Archive</p>
                <div>
                  <EyeIcon />
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};
