import { FaRegUserCircle } from "react-icons/fa";
export const SupportTicketAvatar = () => {
  return (
    <div className="flex items-center gap-2">
      <FaRegUserCircle className="h-10 w-10" />
      <div>
        <p className="m-0 text-blue text-sm font-roboto-flex">Ticket #1234</p>
        <p className="m-0 text-gray-300 text-sm font-roboto-flex">
          hi@figr.design
        </p>
      </div>
    </div>
  );
};
