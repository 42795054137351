import { useEffect, useState } from "react";
import { IoAdd } from "react-icons/io5";
import { HiOutlineMinus } from "react-icons/hi";

export const LisitingAvailability = ({ option, ad, setAd }) => {
  const [count, setCount] = useState(ad.currentAvailibility[option.value]);

  useEffect(() => {
    const updatedcurrentAvailibility = {
      ...ad.currentAvailibility,
    };

    // Update the specific property within the nested object
    updatedcurrentAvailibility[option.value] = count;

    // Update the ad state with the modified currentAvailibility object
    setAd({
      ...ad,
      currentAvailibility: updatedcurrentAvailibility,
    });
  }, [count]);

  return (
    <div className="flex items-center justify-between !px-4 py-2 border-b border-stroke20">
      <div className="">
        <label htmlFor={option.id} className="text-sm m-0">
          {option.name}:
        </label>
      </div>

      <div className="flex items-center gap-2">
        <button
          className="p-1.5 bg-stroke20 rounded-full text-white"
          disabled={count === 0}
          onClick={() => setCount((prev) => prev - 1)}
        >
          <HiOutlineMinus />
        </button>
        <p className="m-0 text-sm text-center w-2.5">{count}</p>
        <button
          className="p-1.5 bg-blue rounded-full text-white"
          disabled={count >= 8}
          onClick={() => setCount((prev) => prev + 1)}
        >
          <IoAdd />
        </button>
      </div>
    </div>
  );
};
