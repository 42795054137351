import { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { IoMdArrowDropright } from "react-icons/io";

export default function ProviderSettings() {
  // state
  const [auth, setAuth] = useAuth();

  const [loading, setLoading] = useState("");

  const [password, setPassword] = useState("");
  const [passwordRetype, setPasswordRetype] = useState("");

  const [emailAdvertise, setEmailAdvertise] = useState();
  const [textAdvertise, setTextAdvertise] = useState();
  const [voiceAdvertise, setVoiceAdvertise] = useState();

  const [emailMarket, setEmailMarket] = useState();
  const [textMarket, setTextMarket] = useState();
  const [voiceMarket, setVoiceMarket] = useState();

  const [emailEssential, setEmailEssential] = useState();
  const [textEssential, setTextEssential] = useState();
  const [voiceEssential, setVoiceEssential] = useState();

  useEffect(() => {
    getNotificationSettings();
  }, []);

  const getNotificationSettings = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post("/users/data-privacy/get-options", {
        userId: auth.user._id,
      });

      if (data?.error) {
        toast.error(data.error);
        setLoading(false);
      } else {
        setEmailAdvertise(data.data.dataPrivacyOptions.advertising.email);
        setEmailEssential(data.data.dataPrivacyOptions.essential.email);
        setEmailMarket(
          data.data.dataPrivacyOptions.marketingAndAnalytics.email
        );

        setTextAdvertise(data.data.dataPrivacyOptions.advertising.text);
        setTextMarket(data.data.dataPrivacyOptions.marketingAndAnalytics.text);
        setTextEssential(data.data.dataPrivacyOptions.essential.text);

        setVoiceAdvertise(data.data.dataPrivacyOptions.advertising.voice);
        setVoiceMarket(
          data.data.dataPrivacyOptions.marketingAndAnalytics.voice
        );
        setVoiceEssential(data.data.dataPrivacyOptions.essential.voice);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    if (passwordRetype !== password) {
      toast.error("Passwords do not match. Please try again.");
      return;
    }

    try {
      setLoading(true);
      const { data } = await axios.put("/update-password", {
        password,
      });
      if (data?.error) {
        toast.error(data.error);
        setLoading(false);
      } else {
        setLoading(false);
        toast.success("Password Updated");

        // Reload the current page
        window.location.href = "/provider/settings";
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleNotificationsUpdate = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const { data } = await axios.post("/users/data-privacy/update-options", {
        userId: auth.user._id,
        dataPrivacyOptions: {
          advertising: {
            email: emailAdvertise,
            text: textAdvertise,
            voice: voiceAdvertise,
          },
          marketingAndAnalytics: {
            email: emailMarket,
            text: textMarket,
            voice: voiceMarket,
          },
        },
      });

      if (data?.error) {
        toast.error(data.error);
        setLoading(false);
      } else {
        toast.success("Notification Settings Updated");
        setLoading(false);

        getNotificationSettings();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="!px-5 py-7 space-y-20">
      <div className="flex items-center justify-center md:justify-start">
        <p className="text-borderBlack text-xl md:text-3xl font-bold">
          Account Settings
        </p>
      </div>
      <div className="space-y-3">
        <Link
          to="/provider/settings/notifications"
          className="flex items-center justify-between max-w-md border border-stroke20 rounded-xl bg-gray-100 text-black !p-5"
        >
          <span>Notifications</span>
          <IoMdArrowDropright />
        </Link>
        <Link
          to="/provider/settings/user"
          className="flex items-center justify-between max-w-md border border-stroke20 rounded-xl bg-gray-100 text-black !p-5"
        >
          <span>User settings</span>
          <IoMdArrowDropright />
        </Link>
      </div>
    </div>
  );
}
