import { Link } from "react-router-dom";
import Logo from "../../components/misc/Logo";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logoutIcon.svg";
import { ReactComponent as ReturnIcon } from "../../assets/icons/returnIcon.svg";
import VerifyIcon from "../../assets/images/verify.png";
import SupportIcon from "../../assets/images/support.png";

export const IdentityVerification = () => {
  return (
    <div className="flex items-center justify-center px-11 py-7">
      <div className="max-w-xl space-y-11">
        <div className="space-y-12">
          <div className="flex items-center justify-center">
            <Logo light />
          </div>
          <div className="space-y-5">
            <p className="m-0 text-center text-3xl text-[#202224] font-bold">
              Identity Verification
            </p>
            <p className=" text-sm text-[#464646] font-roboto-flex">
              We require all Providers to verify their identity in order to use
              our platform. This ensures client safety & listing ownership
              authenticity. We utilize Stripe Identity as a reliable 3rd party
              for this process, and never sell or share your data with any
              additional 3rd parties. Please see our Privacy Policy for more
              information on how we manage your data.
            </p>
          </div>
        </div>
        <div className="space-y-8">
          <Card path="/" heading="Verify ID">
            <img src={VerifyIcon} alt="verify" />
          </Card>
          <Card path="/" heading="Support Ticket">
            <img src={SupportIcon} alt="verify" />
          </Card>
          <Card path="/" heading="Log Out">
            <LogoutIcon />
          </Card>
          <Card path="/" heading="Back to Home">
            <ReturnIcon />
          </Card>
        </div>
        <div>
          <Link
            to="/"
            className=" block text-sm text-[#464646] font-roboto-flex m-0 text-center"
          >
            Not a Provider?
          </Link>
        </div>
      </div>
    </div>
  );
};

const Card = ({ children, path, heading }) => {
  return (
    <Link
      to={path}
      className="flex items-center py-3 md:px-2.5 !gap-5 border border-gray-400 rounded-xl"
    >
      {children}
      <p className="m-0 text-lg text-black font-medium">{heading}</p>
    </Link>
  );
};
