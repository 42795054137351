import { Link } from "react-router-dom";
import { ReactComponent as ProfileDuoToneIcon } from "../../assets/icons/profileDuoTone.svg";
import { ReactComponent as StarIcon } from "../../assets/icons/starIcon.svg";
import { ReactComponent as ArchiveIcon } from "../../assets/icons/archiveIcon.svg";
import clsx from "clsx";

export default function Provider_MessagerCardContacts({
  allThreads,
  activeThread,
}) {
  return (
    <div className="col py-2 px-0">
      <Link to={`/provider/inbox/?thread=${allThreads._id}`}>
        <div
          className={clsx(
            "px-2.5 py-1.5 hover:bg-gray-200 transition-all duration-300 flex items-center rounded-xl min-w-max",
            {
              "bg-gray-200": allThreads._id === activeThread,
            }
          )}
        >
          <div>
            <ProfileDuoToneIcon />
          </div>
          <div className="flex items-center justify-between w-full">
            <div>
              <h5 className="font-roboto-flex text-xl text-black">
                {allThreads?.client_name}
              </h5>
              <p className="m-0 font-roboto-flex italic text-gray-500">
                {allThreads?.listing_name}
              </p>
            </div>
            <div className="space-y-1">
              <div>
                <p className="m-0 text-right text-gray-500 text-xs">18:17</p>
              </div>
              <div className="divide-x divide-[#3C6E7180] flex items-center">
                <div className="flex items-center justify-center pr-2.5">
                  <button>
                    <StarIcon />
                  </button>
                </div>
                <div className="flex items-center justify-center pl-2.5">
                  <button>
                    <ArchiveIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
