import clsx from "clsx";
import { useEffect, useState } from "react";
import { IoCloseCircle } from "react-icons/io5";

export const ListingServicesSelector = ({
  data = [],
  ad,
  setAd,
  selectedServices,
  sectionId,
}) => {
  const [filteredData, setFilteredData] = useState(data);

  const handleSelect = (checked, value) => {
    // Create a copy of the currentAvailability object
    const updatedCurrentServices = { ...ad[sectionId] };

    // Update the specific property within the nested object
    updatedCurrentServices[value] = checked; // Update with the checkbox value

    // Update the ad state with the modified currentAvailability object
    setAd({ ...ad, [sectionId]: updatedCurrentServices });
  };

  useEffect(() => {
    if (data.length > 3) {
      setFilteredData(data.slice(0, 3));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="flex items-center gap-x-1 gap-y-2 flex-wrap">
      {filteredData.map(({ label, id, value }) => (
        <div
          key={id}
          className={clsx(
            "flex items-center justify-between text-xs gap-2.5 border cursor-pointer border-gray-300 py-2.5 !px-5 rounded-3xl",
            {
              "border-transparent bg-[#3C3C43] text-white":
                selectedServices.includes(value),
            }
          )}
          onClick={() => handleSelect(true, value)}
        >
          {label}
          <div className="h-4 w-4 flex items-center justify-center">
            {selectedServices.includes(value) && (
              <button
                onClick={(e) => {
                  handleSelect(false, value);
                  e.stopPropagation();
                }}
              >
                <IoCloseCircle className="h-4 w-4" />
              </button>
            )}
          </div>
        </div>
      ))}
      {data.length > 3 && (
        <button
          className="border text-xs border-gray-300 py-2.5 !px-5 rounded-3xl"
          onClick={() => {
            if (filteredData.length === 3) {
              return setFilteredData(data);
            }
            return setFilteredData(data.slice(0, 3));
          }}
        >
          ......
        </button>
      )}

      {/* <div className="col">
            <input
              type="checkbox"
              id={id}
              name={id}
              checked={ad.amenities.activityCalendar} // Use 'checked' for checkboxes
              onChange={(e) => {
               
              }}
            />
          </div> */}
    </div>
  );
};
