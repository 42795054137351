import { Link } from "react-router-dom";
import { IoOpenOutline } from "react-icons/io5";

export default function EditListingCard({ ad, user }) {
  return (
    <div className="border border-stroke20 rounded-xl">
      <div className="">
        <div className="">
          <div className="!py-4 px-6 flex items-center gap-2">
            <div>
              <img
                src="https://picsum.photos/200"
                alt="profile"
                className="h-8 w-8 object-contain rounded-full"
              />
            </div>{" "}
            <p className="text-lg m-0">{`${ad?.title}`}</p>
          </div>
          <div>
            <img
              src="https://picsum.photos/400"
              alt="main"
              className="w-full object-cover h-52"
            />
          </div>
          <div className="px-2 py-6 flex items-center justify-end gap-3">
            <Link
              className="border border-[#3C3C43] text-[#3C3C43] py-2 px-3 rounded-3xl flex items-center w-fit"
              to={`/provider/listings/edit/${ad?.slug?.title}/${user}/${ad._id}`}
            >
              Edit
            </Link>{" "}
            <Link
              className="border border-[#3C3C43] text-[#3C3C43] py-2 px-3 rounded-3xl flex items-center w-fit"
              to={`/provider/listings/waitlist`}
            >
              Waitlist
            </Link>
            <Link
              target="_blank"
              className="border border-[#3C3C43] text-[#3C3C43] py-2 px-3 rounded-3xl flex items-center gap-2.5 w-fit"
              to={`/listings/${ad.slug.type}/${ad.slug.state}/${ad.slug.county}/${ad.slug.city}/${ad.slug.title}`}
            >
              <IoOpenOutline /> View live listing
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
