import { TiArrowSortedUp } from "react-icons/ti";
import { TiArrowSortedDown } from "react-icons/ti";

export default function ListingAnalytics() {
  return (
    <div className="border border-stroke20 rounded-xl">
      <div className="!py-[18px] px-6 flex items-center gap-2">
        <p className="text-lg m-0">Listing Analytics</p>
      </div>
      <div className="bg-lightestGray py-2.5 px-4 h-52">
        <table className="w-full h-full listing-analytics-table ">
          <thead className="bg-black/5">
            <tr>
              <th>Metric</th>

              <th>Last 30 days</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {rowData.map(({ heading, number }) => (
              <tr>
                <td>{heading}</td>
                <td>
                  <div className="flex items-center justify-between">
                    <p className="m-0">{number}</p>
                    <span>
                      <TiArrowSortedUp className="text-[#6CFF6C]" />
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="p-6 flex items-center justify-end gap-4"></div>
    </div>
  );
}

const rowData = [
  {
    heading: "Contact requests",
    number: "12",
  },
  {
    heading: "Listing Views",
    number: "145",
  },
  {
    heading: "Search Results Views",
    number: "11",
  },
  {
    heading: "Homepage Views",
    number: "3",
  },
];
