import { FiMessageSquare } from "react-icons/fi";
import { GoEye } from "react-icons/go";
import { FaTrash } from "react-icons/fa";

export const ListingWaitList = () => {
  return (
    <div className="pt-7  !px-5 max-h-[calc(100vh-105px)] overflow-auto md:overflow-hidden">
      <div className="flex items-center justify-center md:justify-start">
        <p className="text-borderBlack text-xl md:text-3xl font-bold">
          Listings &gt; Waitlist
        </p>
      </div>
      <div>
        <div className="rounded border border-gray-400 max-w-5xl overflow-hidden">
          <table className="w-full [&_*]:font-nunito-sans [&_*]:lg:text-sm [&_*]:text-[9px]  ">
            <thead className="bg-[#FCFDFD]">
              <tr className="border-b border-gray-300 [&_th]:pb-2.5 [&_th]:!pt-5 [&_th]:px-6  [&_th]:md:px-9">
                <th>Name</th>
                <th>Placement deadline</th>
                <th>Date added </th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {Array(6)
                .fill(1)
                .map(() => (
                  <tr className="border-b border-gray-300 [&_td]:pb-2.5 [&_td]:pt-9 [&_td]:px-6 [&_td]:md:px-9">
                    <td>Christine </td>
                    <td>14 Feb 2019</td>
                    <td>19/8/2024</td>
                    <td>
                      <div className="flex items-center justify-center gap-1.5">
                        <button className="text-xs py-1 px-1.5 md:py-2 md:px-6 bg-stroke50 text-red-500 rounded">
                          <span className="hidden md:block">Message</span>
                          <span className="text-black md:hidden">
                            <FiMessageSquare />
                          </span>
                        </button>
                        <button className="text-xs py-1 px-1.5 md:py-2 md:px-6 bg-stroke50 text-red-500 rounded">
                          <span className="hidden md:block">
                            View assessment
                          </span>
                          <span className="text-black md:hidden">
                            <GoEye />
                          </span>
                        </button>
                        <button className="text-xs py-1 px-1.5 md:py-2 md:px-6 bg-[#ef444433] text-red-500 rounded">
                          <span className="hidden md:block">Remove</span>
                          <span className="text-black md:hidden">
                            <FaTrash />
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
