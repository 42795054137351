import { ConfigProvider, Switch } from "antd";

export const NotificationSettings = () => {
  return (
    <div className="!px-5 py-7 space-y-20">
      <div className="flex items-center justify-center md:justify-start">
        <p className="text-borderBlack text-xl md:text-3xl font-bold">
          Account Settings &gt; Notifications
        </p>
      </div>
      <div className="space-y-6 max-w-2xl border border-[#D4D2D2] rounded-xl p-6">
        <SettingsSection
          heading={"Account Milestones"}
          subtext="These are the UX emails we send out on a scheduled basis."
        />
        <SettingsSection
          heading={"  Account Security"}
          subtext=" Email notifications for account security."
        />
        <SettingsSection
          heading={"DMs for Clients"}
          subtext="SMS notifications for direct messages from clients."
        />
      </div>
    </div>
  );
};

const SettingsSection = ({ heading, subtext }) => {
  return (
    <div className="flex items-center justify-between">
      <div>
        <p className="m-0 font-nunito-sans text-lg font-semibold">{heading}</p>
        <p className="m-0 font-nunito-sans text-xs font-medium text-subtext ">
          {subtext}
        </p>
      </div>
      <Switch defaultChecked />
    </div>
  );
};
