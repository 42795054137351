import { Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/nav/Header";
import { Toaster } from "react-hot-toast";
import CookieProvider from "./context/cookieProvider.js";
import { TrackingProvider, Referrer } from "./context/tracking.js";
import { UpdateLocalStorage } from "./context/loadAssessment.js";

import "./App.css";
import { useState, useEffect, useMemo } from "react";

import Home from "./pages/Home";
import Login from "./pages/auth/LogIn.jsx";
import AccountActivate from "./pages/auth/AccountActivate";
import ForgotPassword from "./pages/auth/ForgetPassword.jsx";
import AccessAccount from "./pages/auth/AccessAccount";

// import AdCreate from './pages/user/listing/AdCreate';

import PrivateRoute from "./components/routes/PrivateRoute";
import AdView from "./pages/AdView.jsx";
import Footer from "./components/nav/Footer";

import Profile from "./pages/user/Profile";
import Settings from "./pages/user/Settings";
import AdEdit from "./pages/user/listing/AdEdit";
import Wishlist from "./pages/user/Wishlist";
import Inquiries from "./pages/user/Inquiries";

import Search from "./pages/Search";
import Unsubscribe from "./pages/Unsubscribe";
import Register from "./pages/register/Register.jsx";
import ContactUs from "./pages/ContactUs.jsx";
import AnchorProvider from "./pages/AnchorProvider.jsx";
import AnchorClient from "./pages/AnchorClient.jsx";
import PrivacyPolicy from "./pages/PrivacyPolicy.jsx";
import TermsOfUse from "./pages/TermsOfUse.jsx";
import BasicCareAssessment from "./pages/assessments/basicCareAssessment.jsx";

import ClientRoute from "./components/routes/ClientRoute";
import RegisterClient from "./pages/register/RegisterClient.jsx";
import ClientDashboard from "./pages/client/ClientDashboard";
import CreateAssessment from "./pages/client/CreateAssessment";
import Engagements from "./pages/client/Engagements";
import ClientHelp from "./pages/client/Help";
import ClientProfile from "./pages/client/Profile";
import ClientSettings from "./pages/client/Settings";
import ClientInbox from "./pages/client/Inbox";

import ProviderRoute from "./components/routes/ProviderRoute";
import RegisterProvider from "./pages/register/RegisterProvider.jsx";
import ProviderDashboard from "./pages/provider/ProviderDashboard";
import ProviderListings from "./pages/provider/Listings";
import ProviderHelp from "./pages/provider/Help";
import ProviderProfile from "./pages/provider/Profile";
import ProviderSettings from "./pages/provider/Settings";
import ProviderInbox from "./pages/provider/Inbox";
import EditListing from "./pages/provider/EditListing";
import { SearchProvider } from "./context/search";

import VerifyAccountMobile from "./pages/auth/VerifyAccountMobileNumber.jsx";

import AgentRoute from "./components/routes/AgentRoute";
// import RegisterAgent from './pages/register/RegisterAgent';
import AgentDashboard from "./pages/agent/AgentDashboard";

import MatchAssessments from "./pages/admin/MatchAssessments.jsx";
import OutOfNetworkRequests from "./pages/admin/OutOfNetworkRequests.jsx";
import ProviderInviteSignup from "./pages/admin/ProviderInviteSignup.jsx";
import AdminRoute from "./components/routes/AdminRoute.jsx";
import { ListingWaitList } from "./pages/provider/listingWaitlist.jsx";
import { NotificationSettings } from "./pages/provider/notificationSettings.jsx";
import { UserSettings } from "./pages/provider/userSettings.jsx";
import { ProviderSupport } from "./pages/provider/support.jsx";
import { IdentityVerification } from "./pages/auth/identityVerification.jsx";

const PageNotFound = () => (
  <div className="text-center p-5">404 PAGE NOT FOUND</div>
);
const ConditionalHeader = ({ children }) => {
  const hideHeaderRoutes = useMemo(
    () => [
      "/client-care-assessment",
      "/login",
      "/register",
      "/register/client",
      "/register/provider",
      "/auth/forgot-password",
      "/auth/verify-account",
      "/provider-invite-signup",
      "/email/unsubscribe",
      "/provider",

      "/auth/verify-identity",
    ],
    []
  );

  const location = useLocation();
  const [shouldDisplayHeader, setShouldDisplayHeader] = useState(
    !hideHeaderRoutes.includes(location.pathname)
  );

  useEffect(() => {
    const shouldDisplay = !hideHeaderRoutes.some((route) =>
      location.pathname.startsWith(route)
    );
    setShouldDisplayHeader(shouldDisplay);
  }, [location.pathname, hideHeaderRoutes]);

  return (
    <div className="min-h-screen flex flex-column">
      <div className="flex-grow-0 ">{shouldDisplayHeader && <Header />}</div>
      <div
        className={`flex-grow-1 ${
          shouldDisplayHeader && "mt-32 md:mt-36 lgTablet:mt-[72px]"
        }`}
      >
        {children}
      </div>
      <div className="flex-grow-0">{shouldDisplayHeader && <Footer />}</div>
    </div>
  );
};

function App() {
  return (
    <Referrer>
      <CookieProvider>
        <SearchProvider>
          <ConditionalHeader>
            <Toaster />
            <TrackingProvider>
              <UpdateLocalStorage>
                <Routes>
                  <Route path="/" element={<Home />} />

                  <Route path="/contact-us" element={<ContactUs />} />

                  <Route
                    path="/learn-more/provider"
                    element={<AnchorProvider />}
                  />

                  <Route path="/learn-more/client" element={<AnchorClient />} />

                  <Route path="/login" element={<Login />} />

                  <Route path="/register" element={<Register />} />

                  <Route path="/register/client" element={<RegisterClient />} />

                  <Route
                    path="/register/provider"
                    element={<RegisterProvider />}
                  />

                  <Route
                    path="/auth/verify-account/:token"
                    element={<VerifyAccountMobile />}
                  />
                  <Route
                    path="/auth/verify-identity"
                    element={<IdentityVerification />}
                  />

                  <Route
                    path="/auth/account-activate/:token"
                    element={<AccountActivate />}
                  />

                  <Route
                    path="/auth/forgot-password"
                    element={<ForgotPassword />}
                  />

                  <Route
                    path="/auth/access-account/:token"
                    element={<AccessAccount />}
                  />

                  <Route
                    path="/listing/:type/:state/:county/:city/:title"
                    element={<AdView />}
                  />

                  <Route path="listings/:type/:state" element={<Search />} />

                  <Route
                    path="listings/:type/:state/:county"
                    element={<Search />}
                  />

                  <Route
                    path="listings/:type/:state/:county/:city"
                    element={<Search />}
                  />

                  <Route
                    path="/policies/terms-of-use"
                    element={<TermsOfUse />}
                  />

                  <Route
                    path="/policies/privacy-policy"
                    element={<PrivacyPolicy />}
                  />

                  <Route
                    path="/provider-invite-signup/:token"
                    element={<ProviderInviteSignup />}
                  />

                  <Route
                    path="/client-care-assessment"
                    element={<BasicCareAssessment />}
                  />

                  <Route path="/email/unsubscribe" element={<Unsubscribe />} />

                  <Route path="/" element={<PrivateRoute />}>
                    {/* routes for client type accounts */}
                    <Route path="client/" element={<ClientRoute />}>
                      <Route path="dashboard" element={<ClientDashboard />} />
                      <Route path="assessment" element={<CreateAssessment />} />
                      <Route path="engagements" element={<Engagements />} />
                      <Route path="inbox" element={<ClientInbox />} />

                      <Route path="help" element={<ClientHelp />} />
                      <Route path="profile" element={<ClientProfile />} />
                      <Route path="settings" element={<ClientSettings />} />

                      <Route path="wishlist" element={<Wishlist />} />
                      <Route path="user/profile" element={<Profile />} />
                      <Route path="user/settings" element={<Settings />} />
                      <Route path="inquiries" element={<Inquiries />} />
                    </Route>

                    {/* routes for provider type accounts */}
                    <Route path="provider/" element={<ProviderRoute />}>
                      <Route path="dashboard" element={<ProviderDashboard />} />
                      <Route path="inbox" element={<ProviderInbox />} />
                      <Route path="listings" element={<ProviderListings />} />
                      <Route path="profile" element={<ProviderProfile />} />
                      <Route path="settings" element={<ProviderSettings />} />

                      <Route
                        path="settings/notifications"
                        element={<NotificationSettings />}
                      />
                      <Route path="settings/user" element={<UserSettings />} />
                      <Route path="support" element={<ProviderSupport />} />
                      <Route
                        path="listings/edit/:title/:userid/:listingid"
                        element={<EditListing />}
                      />
                      <Route
                        path="listings/waitlist"
                        element={<ListingWaitList />}
                      />

                      <Route path="user/profile" element={<Profile />} />
                      <Route path="user/settings" element={<Settings />} />
                      <Route path="user/ad/:slug" element={<AdEdit />} />
                      <Route path="user/inquiries" element={<Inquiries />} />
                    </Route>

                    {/* routes for agent type accounts */}
                    <Route path="agent/" element={<AgentRoute />}>
                      <Route path="dashboard" element={<AgentDashboard />} />
                      <Route path="user/profile" element={<Profile />} />
                      <Route path="user/settings" element={<Settings />} />
                      <Route path="user/inquiries" element={<Inquiries />} />
                    </Route>

                    <Route path="admin/" element={<AdminRoute />}>
                      <Route
                        path="manage-providers"
                        element={<MatchAssessments />}
                      />
                      <Route
                        path="out-of-network-requests"
                        element={<OutOfNetworkRequests />}
                      />
                    </Route>

                    {/* <Route path='listing/create' element={<AdCreate />} /> */}

                    <Route path="user/profile" element={<Profile />} />
                    <Route path="user/settings" element={<Settings />} />
                    {/* <Route path='user/ad/:slug' element={<AdEdit />} /> */}
                    <Route path="user/wishlist" element={<Wishlist />} />
                    {/* <Route path='user/inquiries' element={<Inquiries />} /> */}
                  </Route>

                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </UpdateLocalStorage>
            </TrackingProvider>
          </ConditionalHeader>
        </SearchProvider>
      </CookieProvider>
    </Referrer>
  );
}

export default App;
